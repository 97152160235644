import { TabPanel, Tabs } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { EmployeeAccountsTable } from 'routes/customers/businessCustomerContracts/tabs/EmployeeAccounts/EmployeeAccountsTable';
import { RulesAndSettings } from './RulesAndSettings';
import { DiscountsTable } from 'routes/customers/businessCustomerContracts/tabs/Discounts/DiscountsTable';
import { TransactionsTable } from 'routes/customers/businessCustomerContracts/tabs/Transactions/TransactionsTable';
import { FilesTable } from 'routes/customers/businessCustomerContracts/tabs/FilesTable';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 24px 24px 24px',
    },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

const tab = new Map([
  ['employeeAccounts', EmployeeAccountsTable],
  ['rulesAndSettings', RulesAndSettings],
  ['discounts', DiscountsTable],
  ['transactions', TransactionsTable],
  ['files', FilesTable],
] as Array<[Partial<keyof ReturnType<typeof TransSubtitle>>, FC]>);

export const Tab = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs>
        {Array.from(tab.entries()).map(([key, Component]) => (
          <TabPanel
            key={key}
            label={<TransTitle i18nKey={key} values={{ key }} />}
            value={key}
          >
            {Component ? <Component /> : <span />}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
