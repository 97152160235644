import type { FC } from 'react';
import React, { useState } from 'react';
import { Button, Icon, Loadable, Modal } from '@fleet/shared';
import { Row } from 'react-table';
import { BusinessContractDiscountModalTable } from './BusinessContractDiscountModalTable';
import { Divider } from '@mui/material';
import { BusinessContractDiscountModalSearch } from './BusinessContractDiscountModalSearch';
import { ContractDiscount } from 'dto/businessCustomerContracts';
import { useSelector } from 'store/utils';
import { travelPassDiscountsModalLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface BusinessContractDiscountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (contractDiscount: Row<ContractDiscount>) => void;
  discountType: 'special' | 'travel';
}

export const BusinessContractDiscountModal: FC<BusinessContractDiscountModalProps> =
  ({ onClose, isOpen, onSubmit, discountType }) => {
    const [selectedRow, setSelectedRow] =
      useState<Row<ContractDiscount> | null>();
    const loading = useSelector(travelPassDiscountsModalLoadingSelector);

    const onSelectedRowChange = (row: Row<ContractDiscount> | null) => {
      setSelectedRow(row);
    };

    return (
      <Loadable loading={loading}>
        <Modal
          onClose={onClose}
          title={
            <TransTitle
              i18nKey={
                discountType === 'special'
                  ? 'specialDiscountSearch'
                  : 'travelPassDiscountSearch'
              }
            />
          }
          actionButton={
            <Button
              startIcon={<Icon name="check" size={16} />}
              variant="contained"
              disabled={!selectedRow}
              onClick={() => {
                onSubmit(selectedRow!);
                onClose();
              }}
            >
              <TransButton i18nKey="confirm" />
            </Button>
          }
          open={isOpen}
        >
          <BusinessContractDiscountModalSearch discountType={discountType} />
          <Divider sx={{ mt: 2, mb: 2 }} />
          <BusinessContractDiscountModalTable
            discountType={discountType}
            onSelectedRowChange={onSelectedRowChange}
          />
        </Modal>
      </Loadable>
    );
  };
