import { createReducer } from '@reduxjs/toolkit';
import { Pagination } from '@fleet/shared/dto/pagination';
import {
  getBusinessCustomerAddresses,
  getBusinessCustomerBankAccounts,
  getBusinessCustomerComments,
  getBusinessCustomerContactPersons,
  getBusinessCustomerContactPersonsById,
  getBusinessCustomerContractTab,
  getBusinessCustomers,
  getBusinessCustomersForModal,
  getBusinessCustomerUnits,
  getBusinessCustomerUnitsById,
  getUsersForOrganization,
  resetBusinessCustomers,
  resetCurrentBusinessCustomer,
  setBusinessCustomer,
  setBusinessCustomerContactPerson,
  setBusinessCustomerContactPersonFilter,
  setBusinessCustomersFilter,
  setBusinessCustomersModalFilter,
  setBusinessCustomerUnit,
  setBusinessCustomerUnitFilter,
} from 'features/businessCustomer/businessCustomerActions';
import {
  BusinessCustomer,
  BusinessCustomerAddress,
  BusinessCustomerBankAccount,
  BusinessCustomerComment,
  BusinessCustomerContactPerson,
  BusinessCustomerContactPersonFilter,
  BusinessCustomerFilter,
  BusinessCustomerUnit,
  BusinessCustomerUnitFilter,
  UserForOrganization,
} from 'dto/businessCustomer';
import { BusinessCustomerContract } from 'dto/businessCustomerContracts';

interface BusinessCustomerReducerState {
  customerManagers?: Array<UserForOrganization>;
  current?: BusinessCustomer;
  list?: Pagination<BusinessCustomer>;
  businessCustomerModal?: Pagination<BusinessCustomer>;
  businessCustomerModalFilter: Partial<BusinessCustomerFilter>;
  contracts?: Pagination<BusinessCustomerContract>;
  addresses?: Array<BusinessCustomerAddress>;
  comments?: Array<BusinessCustomerComment>;
  contacts?: Pagination<BusinessCustomerContactPerson>;
  contact?: BusinessCustomerContactPerson;
  units?: Pagination<BusinessCustomerUnit>;
  unit?: BusinessCustomerUnit;
  bankAccounts?: Array<BusinessCustomerBankAccount>;
  contactsFilter: Partial<BusinessCustomerContactPersonFilter>;
  unitsFilter: Partial<BusinessCustomerUnitFilter>;
  businessCustomersFilter: Partial<BusinessCustomerFilter>;
}

const initialState: BusinessCustomerReducerState = {
  businessCustomerModalFilter: {},
  contactsFilter: {},
  unitsFilter: {},
  businessCustomersFilter: {},
};

export const businessCustomerReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setBusinessCustomerContactPerson, (state, { payload }) => {
        state.contact = payload;
        if (!payload) {
          state.contact = undefined;
        }
      })
      .addCase(setBusinessCustomerUnit, (state, { payload }) => {
        state.unit = payload;
        if (!payload) {
          state.unit = undefined;
        }
      })
      .addCase(setBusinessCustomerUnitFilter, (state, action) => {
        state.unitsFilter = action.payload;
      })
      .addCase(getBusinessCustomerUnits.rejected, (state) => {
        state.units = undefined;
      })
      .addCase(getBusinessCustomerUnits.fulfilled, (state, action) => {
        state.units = action.payload;
      })
      .addCase(getBusinessCustomerBankAccounts.rejected, (state) => {
        state.bankAccounts = undefined;
      })
      .addCase(getBusinessCustomerBankAccounts.fulfilled, (state, action) => {
        state.bankAccounts = action.payload;
      })
      .addCase(getBusinessCustomerUnitsById.fulfilled, (state, action) => {
        state.unit = action.payload;
      })
      .addCase(getBusinessCustomersForModal.rejected, (state) => {
        state.businessCustomerModal = undefined;
      })
      .addCase(getBusinessCustomersForModal.fulfilled, (state, action) => {
        state.businessCustomerModal = action.payload;
      })
      .addCase(setBusinessCustomersModalFilter, (state, action) => {
        state.businessCustomerModalFilter = action.payload;
      })
      .addCase(getBusinessCustomerContactPersons.rejected, (state) => {
        state.contacts = undefined;
      })
      .addCase(getBusinessCustomerContactPersons.fulfilled, (state, action) => {
        state.contacts = action.payload;
      })
      .addCase(getBusinessCustomerContactPersonsById.rejected, (state) => {
        state.contact = undefined;
      })
      .addCase(
        getBusinessCustomerContactPersonsById.fulfilled,
        (state, action) => {
          state.contact = action.payload;
        }
      )
      .addCase(resetBusinessCustomers, (state) => {
        state.list = undefined;
      })
      .addCase(getBusinessCustomers.rejected, (state) => {
        state.list = undefined;
      })
      .addCase(getBusinessCustomers.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setBusinessCustomer, (state, action) => {
        state.current = action.payload;
      })
      .addCase(getBusinessCustomerContractTab.rejected, (state) => {
        state.contracts = undefined;
      })
      .addCase(getBusinessCustomerContractTab.fulfilled, (state, action) => {
        state.contracts = action.payload;
      })
      .addCase(getBusinessCustomerAddresses.rejected, (state) => {
        state.addresses = undefined;
      })
      .addCase(getBusinessCustomerAddresses.fulfilled, (state, action) => {
        state.addresses = action.payload;
      })
      .addCase(getBusinessCustomerComments.rejected, (state) => {
        state.comments = undefined;
      })
      .addCase(getBusinessCustomerComments.fulfilled, (state, action) => {
        state.comments = action.payload;
      })
      .addCase(getUsersForOrganization.rejected, (state) => {
        state.customerManagers = undefined;
      })
      .addCase(getUsersForOrganization.fulfilled, (state, action) => {
        state.customerManagers = action.payload;
      })
      .addCase(setBusinessCustomersFilter, (state, action) => {
        state.businessCustomersFilter = action.payload;
      })
      .addCase(setBusinessCustomerContactPersonFilter, (state, action) => {
        state.contactsFilter = action.payload;
      })
      .addCase(resetCurrentBusinessCustomer, (state) => {
        state.current = undefined;
        state.contracts = undefined;
        state.addresses = undefined;
        state.bankAccounts = undefined;
        state.comments = undefined;
        state.contact = undefined;
        state.units = undefined;
      });
  }
);
