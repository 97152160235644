import { createAsyncThunk } from 'store/utils';
import {
  DistanceConversionRate,
  DistanceConversionRateException,
  DistanceConversionRateExceptionPayload,
  DistanceConversionRatePayload,
  ImportDetails,
  Line,
  LineTemplate,
  LoyaltyParameters,
  LoyaltyParametersPayload,
  LoyaltyTier,
  LoyaltyTierFormData,
  LoyaltyTierLocalization,
  LoyaltyTierLocalizationPayload,
  PlaceClass,
  ServiceCode,
  TargetGroup,
  TargetGroupLocalization,
  TargetGroupLocalizationPayload,
  TransactionConversionRate,
  TransactionConversionRatePayload,
} from 'dto/loyaltyProgram';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import qs from 'qs';
import { api } from '@fleet/shared';

export const getPlaceClasses = createAsyncThunk<Array<PlaceClass>>(
  'loyaltyProgram/getPlaceClasses',
  async (_, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return (
      await api.get(`/organizations/${currentBusinessEntityId}/place-classes`)
    ).data.items;
  }
);

export const getDistanceConversionRates = createAsyncThunk<
  Array<DistanceConversionRate>
>('loyaltyProgram/getDistanceConversionRates', async (_, { getState }) => {
  const { currentBusinessEntityId } = getState().common;

  return (
    await api.get(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/distance-conversion-rates`
    )
  ).data.items;
});

export const getTransactionConversionRates = createAsyncThunk<
  Array<TransactionConversionRate>
>('loyaltyProgram/getTransactionConversionRates', async (_, { getState }) => {
  const { currentBusinessEntityId } = getState().common;

  return (
    await api.get(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/transaction-conversion-rates`
    )
  ).data.items;
});

export const updateOrCreateTransactionConversionRate = createAsyncThunk<
  void,
  TransactionConversionRatePayload
>(
  'loyaltyProgram/updateOrCreateDistanceConversionRate',
  async ({ id, ...payload }, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return await (id ? api.put : api.post)(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/transaction-conversion-rates${
        id ? `/${id}` : ''
      }`,
      payload
    );
  }
);

export const deleteTransactionConversionRates = createAsyncThunk<
  void,
  Array<TransactionConversionRate>
>(
  'loyaltyProgram/deleteDistanceConversionRates',
  async (transactionConversionRates, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    await Promise.all(
      transactionConversionRates.map(({ id }) =>
        api.delete(
          `/organizations/${currentBusinessEntityId}/loyalty-programs/transaction-conversion-rates/${id}`
        )
      )
    );
  }
);

export const updateOrCreateDistanceConversionRate = createAsyncThunk<
  void,
  DistanceConversionRatePayload
>(
  'loyaltyProgram/updateOrCreateDistanceConversionRate',
  async ({ id, ...payload }, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return await (id ? api.put : api.post)(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/distance-conversion-rates${
        id ? `/${id}` : ''
      }`,
      payload
    );
  }
);

export const deleteDistanceConversionRates = createAsyncThunk<
  void,
  Array<DistanceConversionRate>
>(
  'loyaltyProgram/deleteDistanceConversionRates',
  async (distanceConversionRates, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    await Promise.all(
      distanceConversionRates.map(({ id }) =>
        api.delete(
          `/organizations/${currentBusinessEntityId}/loyalty-programs/distance-conversion-rates/${id}`
        )
      )
    );
  }
);

export const getLoyaltyParameters = createAsyncThunk<LoyaltyParameters>(
  'loyaltyProgram/getLoyaltyParameters',
  async (_, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return (
      await api.get(
        `/organizations/${currentBusinessEntityId}/loyalty-programs/parameters`
      )
    ).data;
  }
);

export const updateLoyaltyParameters = createAsyncThunk<
  void,
  LoyaltyParametersPayload
>('loyaltyProgram/updateLoyaltyParameters', async (values, { getState }) => {
  const { currentBusinessEntityId } = getState().common;

  return await api.put(
    `/organizations/${currentBusinessEntityId}/loyalty-programs/parameters`,
    values
  );
});

export const getDistanceConversionRateExceptions = createAsyncThunk<
  Array<DistanceConversionRateException>
>(
  'loyaltyProgram/getDistanceConversionRateExceptions',
  async (_, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return (
      await api.get(
        `/loyalty-programs/services/distance-conversion-rates${qs.stringify(
          { organizationId: currentBusinessEntityId },
          {
            addQueryPrefix: true,
          }
        )}`
      )
    ).data.items;
  }
);

export const getActiveLines = createAsyncThunk<Array<Line>>(
  'loyaltyProgram/getActiveLines',
  async (_, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return (
      await api.get(`/organizations/${currentBusinessEntityId}/active-lines`)
    ).data.items;
  }
);

export const getLineTemplates = createAsyncThunk<Array<LineTemplate>, number>(
  'loyaltyProgram/getLineTemplates',
  async (lineId) =>
    (await api.get(`/lines/${lineId}/active-line-templates`)).data.items
);

export const getServiceCodes = createAsyncThunk<Array<ServiceCode>, number>(
  'loyaltyProgram/getServiceCodes',
  async (lineTemplateId) =>
    (await api.get(`/line-templates/${lineTemplateId}/active-service-code`))
      .data.items
);

export const updateOrCreateDistanceConversionRateException = createAsyncThunk<
  void,
  Partial<DistanceConversionRateExceptionPayload>
>(
  'loyaltyProgram/updateOrCreateDistanceConversionRateException',
  async ({ id, ...payload }) =>
    await (id ? api.put : api.post)(
      `/loyalty-programs/services/distance-conversion-rates${
        id ? `/${id}` : ''
      }`,
      payload
    )
);

export const deleteDistanceConversionRateException = createAsyncThunk<
  void,
  DistanceConversionRateException
>(
  'loyaltyProgram/deleteDistanceConversionRateException',
  async ({ id }) =>
    await api.delete(
      `/loyalty-programs/services/distance-conversion-rates/${id}`
    )
);

export const getTargetGroupsWithLocalizations = createAsyncThunk<
  Array<TargetGroup>
>('loyaltyProgram/getTargetGroups', async (_, { getState }) => {
  const { currentBusinessEntityId } = getState().common;

  const items = (
    await api.get(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/target-groups`
    )
  ).data.items;

  return await Promise.all(
    items.map(async (targetGroup: TargetGroup) => {
      const localizations = (
        await api.get(
          `/loyalty-programs/target-groups/${targetGroup.id}/localizations`
        )
      ).data.items;

      return { ...targetGroup, localizations };
    })
  );
});

export const getTargetGroupLocalizations = createAsyncThunk<
  Array<TargetGroupLocalization>,
  string
>(
  'loyaltyProgram/getTargetGroupLocalizations',
  async (id) =>
    (await api.get(`/loyalty-programs/target-groups/${id}/localizations`)).data
      .items
);

export const updateOrCreateTargetGroup = createAsyncThunk<void, TargetGroup>(
  'loyaltyProgram/updateOrCreateTargetGroup',
  async ({ id, ...rest }, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    return await (id ? api.put : api.post)(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/target-groups${
        id ? `/${id}` : ''
      }`,
      rest
    );
  }
);

export const deleteTargetGroups = createAsyncThunk<void, Array<TargetGroup>>(
  'loyaltyProgram/deleteTargetGroup',
  async (targetGroups, { getState }) => {
    const { currentBusinessEntityId } = getState().common;

    await Promise.all(
      targetGroups.map(
        async ({ id }) =>
          await api.delete(
            `/organizations/${currentBusinessEntityId}/loyalty-programs/target-groups/${id}`
          )
      )
    );
  }
);

export const updateOrCreateTargetGroupLocalization = createAsyncThunk<
  void,
  TargetGroupLocalizationPayload & { targetGroupId: string }
>(
  'loyaltyProgram/updateOrCreateTargetGroupLocalization',
  async ({ targetGroupId, id, ...rest }) =>
    await (id ? api.put : api.post)(
      `/loyalty-programs/target-groups/${targetGroupId}/localizations${
        id ? `/${id}` : ''
      }`,
      { ...rest }
    )
);

export const deleteTargetGroupLocalizations = createAsyncThunk<
  void,
  { targetGroupId: string; localizations: Array<TargetGroupLocalization> }
>(
  'loyaltyProgram/deleteTargetGroupLocalization',
  async ({ targetGroupId, localizations }) => {
    await Promise.all(
      localizations.map(
        async ({ id }) =>
          await api.delete(
            `/loyalty-programs/target-groups/${targetGroupId}/localizations/${id}`
          )
      )
    );
  }
);

export const getLoyaltyPointsImportList = createAsyncThunk<
  Pagination<ImportDetails>,
  PaginationParams | undefined
>(
  'loyaltyProgram/getLoyaltyPointsImportList',
  async (filter) =>
    (
      await api.get(
        `/loyalty-programs/points/imports${qs.stringify(filter, {
          addQueryPrefix: true,
        })}`
      )
    ).data
);

export const updateOrCreateLoyaltyProgramTierLocalization = createAsyncThunk<
  void,
  LoyaltyTierLocalizationPayload & { tierId: string }
>(
  'loyaltyProgram/createLoyaltyProgramTierLocalization',
  async ({ tierId, id, ...rest }) =>
    await (id ? api.put : api.post)(
      `/loyalty-programs/tiers/${tierId}/localizations${id ? `/${id}` : ''}`,
      { ...rest }
    )
);

export const getLoyaltyTierLocalizations = createAsyncThunk<
  Array<LoyaltyTierLocalization>,
  string
>(
  'loyaltyProgram/getLoyaltyTierLocalizations',
  async (tierId) =>
    (await api.get(`/loyalty-programs/tiers/${tierId}/localizations`)).data
      .items
);

export const deleteLoyaltyProgramTierLocalizations = createAsyncThunk<
  void,
  { tierId: string; localizations: Array<LoyaltyTierLocalization> }
>(
  'loyaltyProgram/deleteLoyaltyProgramTierLocalizations',
  async ({ tierId, localizations }) => {
    await Promise.all(
      localizations.map(
        async ({ id }) =>
          await api.delete(
            `/loyalty-programs/tiers/${tierId}/localizations/${id}`
          )
      )
    );
  }
);

export const getLoyaltyProgramTiersWithLocalizations = createAsyncThunk<
  Array<LoyaltyTier>
>('loyaltyProgram/getLoyaltyProgramTiers', async (_, { getState }) => {
  const { currentBusinessEntityId } = getState().common;
  const items = (
    await api.get(
      `/organizations/${currentBusinessEntityId}/loyalty-programs/tiers`
    )
  ).data.items;

  return await Promise.all(
    items.map(async (tier: LoyaltyTier) => {
      const localizations = (
        await api.get(`/loyalty-programs/tiers/${tier.id}/localizations`)
      ).data.items;

      return { ...tier, localizations };
    })
  );
});

export const updateLoyaltyProgramTier = createAsyncThunk<
  Array<LoyaltyTier>,
  LoyaltyTierFormData['loyaltyProgramTiers']
>(
  'loyaltyProgram/createLoyaltyProgramTier',
  async (loyaltyProgramTiers, { getState }) => {
    const { currentBusinessEntityId } = getState().common;
    const mutatePointsFrom = (
      tiers: Omit<LoyaltyTier, 'localizations'>[]
    ): Omit<LoyaltyTier, 'localizations'>[] => {
      let prevPointsTo: number | null = null;
      return tiers.map((tier, index) => {
        const newObj = { ...tier };
        if (index > 0 && prevPointsTo !== null) {
          newObj.pointsFrom = Math.max(Number(prevPointsTo) + 1);
        }
        newObj.rate = Number(newObj.rate);
        newObj.pointsFrom = Number(newObj.pointsFrom);
        newObj.pointsTo = Number(newObj.pointsTo);
        prevPointsTo = newObj.pointsTo;
        return newObj;
      });
    };

    return (
      await api.put(
        `/organizations/${currentBusinessEntityId}/loyalty-programs/tiers/bulk`,
        { loyaltyProgramTiers: mutatePointsFrom(loyaltyProgramTiers) }
      )
    ).data;
  }
);
