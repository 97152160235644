import { Stack, Typography, Button } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { DistanceConversionRateExceptionForm } from 'routes/loyaltyProgram/tabs/DistanceConversionRates/DistanceConversionRateExceptionForm';
import { useSelector } from 'store/utils';
import { distanceConversionRateExceptionSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';
import { FC, useState } from 'react';

interface ServiceLevelProps {}

export const DistanceConversionRateExceptions: FC<ServiceLevelProps> = () => {
  const [newService, setNewService] = useState(false);
  const distanceConfList = useSelector(distanceConversionRateExceptionSelector);

  const toggle = () => setNewService((prev) => !prev);

  return (
    <>
      <Stack
        sx={{ my: 1 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle" fontWeight="400">
          Service level exceptions
        </Typography>
        {newService ? (
          <Button
            variant="text"
            onClick={toggle}
            startIcon={<Icon name="minus" />}
          >
            <TransButton i18nKey="close" />
          </Button>
        ) : (
          <Button
            variant="text"
            onClick={toggle}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        )}
      </Stack>
      {newService && <DistanceConversionRateExceptionForm closeForm={toggle} />}
      {distanceConfList?.map((d) => (
        <DistanceConversionRateExceptionForm data={d} key={d.id} />
      ))}
    </>
  );
};
