import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { Row, useFilters, useRowSelect, useTable } from 'react-table';
import { Radio, Stack, Typography } from '@mui/material';
import { Loadable, Table, TableColumns } from '@fleet/shared';
import { formatDate } from '@fleet/shared/utils/date';
import { ContractDiscount } from 'dto/businessCustomerContracts';
import { useSelector } from 'store/utils';
import {
  specialDiscountsSelector,
  travelPassDiscountsSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { travelPassDiscountsModalLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTableHead } from 'i18n/trans/table';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface TravelPassDiscountTableProps {
  onSelectedRowChange: (row: Row<ContractDiscount> | null) => void;
  discountType: 'special' | 'travel';
}

export const BusinessContractDiscountModalTable: FC<TravelPassDiscountTableProps> =
  ({ onSelectedRowChange, discountType }) => {
    const travelPassDiscounts = useSelector(travelPassDiscountsSelector);
    const specialDiscounts = useSelector(specialDiscountsSelector);
    const loading = useSelector(travelPassDiscountsModalLoadingSelector);
    const [selectedDiscount, setSelectedDiscount] =
      useState<Row<ContractDiscount>>();

    const data = useMemo(() => {
      if (discountType === 'travel') {
        return travelPassDiscounts ?? [];
      }
      return specialDiscounts ?? [];
    }, [discountType, specialDiscounts, travelPassDiscounts]);

    const columns = useMemo<TableColumns<ContractDiscount>>(
      () => [
        {
          id: 'selection',
          width: 40,
          Cell: ({ row }: { row: Row<ContractDiscount> }) => (
            <Radio
              onClick={() => {
                onSelectedRowChange(row);
                setSelectedDiscount(row);
              }}
              size="small"
              checked={
                String(row.original.id) ===
                String(selectedDiscount?.original.id)
              }
            />
          ),
        },
        {
          id: 'name',
          accessor: 'name',
          Header: <TransTableHead i18nKey="discountName" />,
        },
        {
          id: 'calculationType',
          accessor: ({ calculationType }) => calculationType?.name,
          Header: <TransTableHead i18nKey="calculationType" />,
        },
        {
          id: 'validity.from',
          accessor: ({ validity }) => formatDate(validity?.from),
          Header: <TransTableHead i18nKey="validFrom" />,
        },
        {
          id: 'validity.to',
          accessor: ({ validity }) => formatDate(validity?.to),
          Header: <TransTableHead i18nKey="validUntil" />,
        },
      ],
      [onSelectedRowChange, selectedDiscount?.original.id]
    );

    const table = useTable(
      {
        data,
        columns,
      },
      useFilters,
      useRowSelect
    );

    useComponentWillUnmount(() => {
      onSelectedRowChange(null);
    });

    return (
      <Loadable loading={loading}>
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle" fontWeight="700" sx={{ mr: 2 }}>
            <TransSubtitle i18nKey="searchResults" />
          </Typography>
        </Stack>
        <Table table={table} />
      </Loadable>
    );
  };
