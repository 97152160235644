import { createReducer } from '@reduxjs/toolkit';
import { Pagination } from '@fleet/shared/dto/pagination';

import {
  getBusinessCustomerContractById,
  getBusinessCustomerContractRules,
  getBusinessCustomerContracts,
  getBusinessCustomerSpecialDiscounts,
  getBusinessCustomerTravelPassDiscounts,
  getContractFiles,
  getEmployeeAccountById,
  getEmployeeAccounts,
  getEmployeeAccountsLight,
  getOrganizationServices,
  getSpecialDiscounts,
  getTransactions,
  getTravelPassDiscounts,
  resetBusinessCustomerContracts,
  resetCurrentBusinessCustomerContract,
  resetSpecialDiscountsSearchTable,
  resetTravelPassDiscountsSearchTable,
  setBusinessCustomerContractsFilter,
  setBusinessCustomerTransactionFilter,
  setContractDiscountsFilter,
  setEmployeeAccount,
  setEmployeeAccounts,
  setEmployeeAccountsFilter,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import {
  BusinessCustomerContract,
  BusinessCustomerContractRules,
  BusinessCustomerContractsFilter,
  BusinessCustomerContractSpecialDiscount,
  BusinessCustomerContractTravelPassDiscount,
  ContractDiscount,
  ContractDiscountSearchFilter,
  ContractFile,
  EmployeeAccount,
  EmployeeAccountsFilter,
  OrganizationService,
  Transaction,
  TransactionFilter,
} from 'dto/businessCustomerContracts';
import { Classifier } from '@fleet/shared/dto/classifier';

interface BusinessCustomerContractsReducerState {
  list?: Pagination<BusinessCustomerContract>;
  current?: BusinessCustomerContract;
  businessCustomerContractTravelPassDiscounts?: Array<BusinessCustomerContractTravelPassDiscount>;
  businessCustomerContractSpecialDiscounts?: Array<BusinessCustomerContractSpecialDiscount>;
  travelPassDiscounts?: Array<ContractDiscount>;
  specialDiscounts?: Array<ContractDiscount>;
  employeeAccounts?: Pagination<EmployeeAccount>;
  employeeAccount?: EmployeeAccount;
  transactions?: Pagination<Transaction>;
  transactionFilter: Partial<TransactionFilter>;
  contractDiscountFilter: Partial<ContractDiscountSearchFilter>;
  employeeAccountsFilter: Partial<EmployeeAccountsFilter>;
  contractRules?: BusinessCustomerContractRules;
  employeeAccountsLight?: Array<Classifier<number>>;
  organizationServices?: Array<OrganizationService>;
  businessCustomersContractsFilter: Partial<BusinessCustomerContractsFilter>;
  contractFiles?: Array<ContractFile>;
}

const initialState: BusinessCustomerContractsReducerState = {
  transactionFilter: {},
  contractDiscountFilter: {},
  employeeAccountsFilter: {},
  businessCustomersContractsFilter: {},
};

export const businessCustomerContractsReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getEmployeeAccountsLight.rejected, (state) => {
        state.employeeAccountsLight = undefined;
      })
      .addCase(getEmployeeAccountsLight.fulfilled, (state, action) => {
        state.employeeAccountsLight = action.payload;
      })
      .addCase(getContractFiles.rejected, (state) => {
        state.contractFiles = undefined;
      })
      .addCase(getContractFiles.fulfilled, (state, action) => {
        state.contractFiles = action.payload;
      })
      .addCase(getTransactions.rejected, (state) => {
        state.transactions = undefined;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addCase(setBusinessCustomerTransactionFilter, (state, action) => {
        state.transactionFilter = action.payload;
      })
      .addCase(resetBusinessCustomerContracts, (state) => {
        state.list = undefined;
      })
      .addCase(getBusinessCustomerContracts.rejected, (state) => {
        state.list = undefined;
      })
      .addCase(getBusinessCustomerContracts.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(getTravelPassDiscounts.rejected, (state) => {
        state.travelPassDiscounts = undefined;
      })
      .addCase(getTravelPassDiscounts.fulfilled, (state, action) => {
        state.travelPassDiscounts = action.payload;
      })
      .addCase(getSpecialDiscounts.rejected, (state) => {
        state.specialDiscounts = undefined;
      })
      .addCase(getSpecialDiscounts.fulfilled, (state, action) => {
        state.specialDiscounts = action.payload;
      })
      .addCase(getEmployeeAccountById.rejected, (state) => {
        state.employeeAccount = undefined;
      })
      .addCase(getEmployeeAccountById.fulfilled, (state, action) => {
        state.employeeAccount = action.payload;
      })
      .addCase(getBusinessCustomerSpecialDiscounts.rejected, (state) => {
        state.businessCustomerContractSpecialDiscounts = undefined;
      })
      .addCase(
        getBusinessCustomerSpecialDiscounts.fulfilled,
        (state, action) => {
          state.businessCustomerContractSpecialDiscounts = action.payload;
        }
      )
      .addCase(getBusinessCustomerTravelPassDiscounts.rejected, (state) => {
        state.businessCustomerContractTravelPassDiscounts = undefined;
      })
      .addCase(
        getBusinessCustomerTravelPassDiscounts.fulfilled,
        (state, action) => {
          state.businessCustomerContractTravelPassDiscounts = action.payload;
        }
      )
      .addCase(setEmployeeAccount, (state, { payload }) => {
        state.employeeAccount = payload;
        if (!payload) {
          state.employeeAccount = undefined;
        }
      })
      .addCase(resetCurrentBusinessCustomerContract, (state) => {
        state.transactions = undefined;
        state.transactionFilter = {};
        state.employeeAccount = undefined;
        state.employeeAccounts = undefined;
        state.employeeAccountsFilter = {};
        state.current = undefined;
      })
      .addCase(setEmployeeAccounts, (state, { payload }) => {
        state.employeeAccounts = payload;
        if (!payload) {
          state.employeeAccounts = undefined;
        }
      })
      .addCase(getOrganizationServices.fulfilled, (state, action) => {
        state.organizationServices = action.payload;
      })
      .addCase(getOrganizationServices.rejected, (state) => {
        state.organizationServices = undefined;
      })
      .addCase(getBusinessCustomerContractRules.rejected, (state) => {
        state.contractRules = undefined;
      })
      .addCase(getBusinessCustomerContractRules.fulfilled, (state, action) => {
        state.contractRules = action.payload;
      })
      .addCase(getEmployeeAccounts.rejected, (state) => {
        state.employeeAccounts = undefined;
      })
      .addCase(getEmployeeAccounts.fulfilled, (state, action) => {
        state.employeeAccounts = action.payload;
      })
      .addCase(getBusinessCustomerContractById.rejected, (state) => {
        state.current = undefined;
      })
      .addCase(getBusinessCustomerContractById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(setBusinessCustomerContractsFilter, (state, action) => {
        state.businessCustomersContractsFilter = action.payload;
      })
      .addCase(setEmployeeAccountsFilter, (state, action) => {
        state.employeeAccountsFilter = action.payload;
      })
      .addCase(resetTravelPassDiscountsSearchTable, (state) => {
        state.travelPassDiscounts = undefined;
      })
      .addCase(resetSpecialDiscountsSearchTable, (state) => {
        state.specialDiscounts = undefined;
      })
      .addCase(setContractDiscountsFilter, (state, action) => {
        state.contractDiscountFilter = action.payload;
      });
  }
);
