import { createReducer } from '@reduxjs/toolkit';
import {
  getCustomerProfile,
  getCustomerProfileCards,
  getCustomerProfileConsentHistory,
  getCustomerProfileList,
  getCustomerProfileListLight,
  getCustomerProfileLoyaltySummary,
  getCustomerProfileLoyaltyTargetGroups,
  getCustomerProfileTransactions,
  getCustomerProfileTravelPasses,
  getCustomerProfileVoucherBalance,
  getCustomerProfileVouchers,
  getTravelPasses,
  getVouchers,
  resetCustomerProfileList,
  resetTravelPassSearchTable,
  resetVoucherSearchTable,
  setCustomerProfileCardFilter,
  setCustomerProfileFilter,
  setCustomerProfileTravelPassFilter,
  setCustomerProfileVoucherFilter,
  setTravelPassFilter,
  setVouchersFilter,
} from 'features/customerProfile/customerProfileActions';
import type {
  CustomerProfile,
  CustomerProfileCard,
  CustomerProfileConsentHistory,
  CustomerProfileSearchFilter,
  CustomerProfileTransactions,
  CustomerProfileTravelPasses,
  CustomerProfileTravelPassFilter,
  CustomerProfileVoucherFilter,
  CustomerProfileWithLoyalty,
  TravelPass,
  TravelPassSearchFilter,
  Voucher,
  VoucherSearchFilter,
} from 'dto/customerProfile';
import {
  CustomerProfileCardFilter,
  CustomerProfileVouchers,
} from 'dto/customerProfile';
import { Pagination } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';

interface CustomerProfileState {
  customerTravelPasses?: Pagination<CustomerProfileTravelPasses>;
  customerVouchers?: Pagination<CustomerProfileVouchers>;
  customerVoucherBalance?: number;
  travelPasses?: Pagination<TravelPass>;
  vouchers?: Pagination<Voucher>;
  list?: Pagination<CustomerProfile>;
  current?: CustomerProfileWithLoyalty;
  cards?: Pagination<CustomerProfileCard>;
  filter: Partial<CustomerProfileSearchFilter>;
  travelPassFilter: Partial<TravelPassSearchFilter>;
  vouchersFilter: Partial<VoucherSearchFilter>;
  customerProfileVoucherFilter: Partial<CustomerProfileVoucherFilter>;
  customerProfileTravelPassFilter: Partial<CustomerProfileTravelPassFilter>;
  customerProfileCardFilter: Partial<CustomerProfileCardFilter>;
  transactions?: Pagination<CustomerProfileTransactions>;
  consents?: Pagination<CustomerProfileConsentHistory>;
  loyaltyTargetGroups?: Array<Classifier>;
  customerProfilesLight?: Array<CustomerProfile>;
}

const initialState: CustomerProfileState = {
  filter: {},
  travelPassFilter: {},
  vouchersFilter: {},
  customerProfileVoucherFilter: {},
  customerProfileTravelPassFilter: {},
  customerProfileCardFilter: {},
};

export const customerProfileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getCustomerProfileLoyaltyTargetGroups.fulfilled,
      (state, action) => {
        state.loyaltyTargetGroups = action.payload;
      }
    )
    .addCase(getTravelPasses.rejected, (state) => {
      state.travelPasses = undefined;
    })
    .addCase(getTravelPasses.fulfilled, (state, action) => {
      state.travelPasses = action.payload;
    })
    .addCase(getVouchers.rejected, (state) => {
      state.vouchers = undefined;
    })
    .addCase(getVouchers.fulfilled, (state, action) => {
      state.vouchers = action.payload;
    })
    .addCase(getCustomerProfileVoucherBalance.fulfilled, (state, action) => {
      state.customerVoucherBalance = action.payload;
    })
    .addCase(getCustomerProfileVoucherBalance.rejected, (state) => {
      state.customerVoucherBalance = undefined;
    })
    .addCase(getCustomerProfileVouchers.rejected, (state) => {
      state.customerVouchers = undefined;
    })
    .addCase(getCustomerProfileVouchers.fulfilled, (state, action) => {
      state.customerVouchers = action.payload;
    })
    .addCase(getCustomerProfileTravelPasses.rejected, (state) => {
      state.customerTravelPasses = undefined;
    })
    .addCase(getCustomerProfileTravelPasses.fulfilled, (state, action) => {
      state.customerTravelPasses = action.payload;
    })
    .addCase(getCustomerProfileCards.rejected, (state) => {
      state.cards = undefined;
    })
    .addCase(getCustomerProfileCards.fulfilled, (state, action) => {
      state.cards = action.payload;
    })
    .addCase(getCustomerProfileTransactions.rejected, (state) => {
      state.transactions = undefined;
    })
    .addCase(getCustomerProfileTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload;
    })
    .addCase(getCustomerProfileConsentHistory.rejected, (state) => {
      state.consents = undefined;
    })
    .addCase(getCustomerProfileConsentHistory.fulfilled, (state, action) => {
      state.consents = action.payload;
    })
    .addCase(resetCustomerProfileList, (state) => {
      state.list = undefined;
    })
    .addCase(getCustomerProfileList.rejected, (state) => {
      state.list = undefined;
    })
    .addCase(getCustomerProfileList.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setCustomerProfileFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(setTravelPassFilter, (state, action) => {
      state.travelPassFilter = action.payload;
    })
    .addCase(setVouchersFilter, (state, action) => {
      state.vouchersFilter = action.payload;
    })
    .addCase(setCustomerProfileVoucherFilter, (state, action) => {
      state.customerProfileVoucherFilter = action.payload;
    })
    .addCase(setCustomerProfileTravelPassFilter, (state, action) => {
      state.customerProfileTravelPassFilter = action.payload;
    })
    .addCase(setCustomerProfileCardFilter, (state, action) => {
      state.customerProfileCardFilter = action.payload;
    })
    .addCase(resetTravelPassSearchTable, (state) => {
      state.travelPasses = undefined;
    })
    .addCase(resetVoucherSearchTable, (state) => {
      state.vouchers = undefined;
    })
    .addCase(getCustomerProfile.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getCustomerProfileLoyaltySummary.fulfilled, (state, action) => {
      state.current!.loyalty = action.payload;
    })
    .addCase(getCustomerProfileListLight.rejected, (state) => {
      state.customerProfilesLight = undefined;
    })
    .addCase(getCustomerProfileListLight.fulfilled, (state, action) => {
      state.customerProfilesLight = action.payload;
    });
});
