import { TabPanel, Tabs } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { BankAccounts } from 'routes/customers/businessCustomer/tabs/BankAccounts';
import { Comments } from 'routes/customers/businessCustomer/tabs/Comments';
import { Units } from 'routes/customers/businessCustomer/tabs/Units/Units';
import { Contract } from 'routes/customers/businessCustomer/tabs/Contract';
import { Addresses } from 'routes/customers/businessCustomer/tabs/Adresses';
import { ContactPersons } from 'routes/customers/businessCustomer/tabs/ContactPersons/ContactPersons';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 24px 24px 24px',
    },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

export const BusinessCustomerTabs = () => {
  const classes = useStyles();

  const tab = new Map([
    ['contracts', Contract],
    ['addresses', Addresses],
    ['bankAccounts', BankAccounts],
    ['comments', Comments],
    ['contactPersons', ContactPersons],
    ['units', Units],
  ] as Array<[Partial<keyof ReturnType<typeof TransSubtitle>>, FC]>);

  return (
    <div className={classes.root}>
      <Tabs>
        {Array.from(tab.entries()).map(([key, Component]) => (
          <TabPanel
            key={key}
            label={<TransTitle i18nKey={key} values={{ key }} />}
            value={key}
          >
            {Component ? <Component /> : <span />}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
