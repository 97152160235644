import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { BusinessCustomer, BusinessCustomerFilter } from 'dto/businessCustomer';
import qs from 'qs';
import { Classifier } from '@fleet/shared/dto/classifier';

export const fetchBusinessCustomers = async (
  filter?: Partial<BusinessCustomerFilter>
) =>
  await api.get<Pagination<BusinessCustomer>>(
    `business-customers${qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
      arrayFormat: 'comma',
      encode: false,
    })}`
  );

export const fetchUnits = async (businessCustomerId: number) =>
  (
    await api.get<{ items: Array<Classifier<number>> }>(
      `/business-customers/${businessCustomerId}/units-light`
    )
  ).data.items;
