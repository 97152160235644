import { createSelector, selector } from 'store/utils';

export const selectPlaceClasses = selector(
  (state) => state.loyaltyProgram.placeClasses
);

export const placeClassesSelector = createSelector(selectPlaceClasses);

export const selectDistanceConversionRates = selector(
  (state) => state.loyaltyProgram.distanceConversionRates
);

export const distanceConversionRatesSelector = createSelector(
  selectDistanceConversionRates
);

export const selectDistanceConversionRateExceptions = selector(
  (state) => state.loyaltyProgram.distanceConversionRateExceptions
);

export const distanceConversionRateExceptionSelector = createSelector(
  selectDistanceConversionRateExceptions
);

export const selectLines = selector((state) => state.loyaltyProgram.lines);

export const lineSelector = createSelector(selectLines);

export const selectLoyaltyParameters = selector(
  (state) => state.loyaltyProgram.parameters
);
export const loyaltyParametersSelector = createSelector(
  selectLoyaltyParameters
);

export const selectTargetGroups = selector(
  (state) => state.loyaltyProgram.targetGroups
);

export const targetGroupsSelector = createSelector(selectTargetGroups);

export const selectLoyaltyPointsImportList = selector(
  (state) => state.loyaltyProgram.loyaltyPointsImportList
);

export const loyaltyPointsImportListSelector = createSelector(
  selectLoyaltyPointsImportList
);

export const selectTransactionConversionRates = selector(
  (state) => state.loyaltyProgram.transactionConversionRates
);

export const transactionConversionRatesSelector = createSelector(
  selectTransactionConversionRates
);

export const selectLoyaltyProgramTiers = selector(
  (state) => state.loyaltyProgram.loyaltyTiers
);
export const loyaltyProgramTiersSelector = createSelector(
  selectLoyaltyProgramTiers
);
