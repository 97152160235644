import { createSelector, selector } from 'store/utils';

export const selectBusinessCustomerContracts = selector(
  (state) => state.businessCustomerContracts.list
);
export const businessCustomerContractsSelector = createSelector(
  selectBusinessCustomerContracts
);

export const selectCurrentBusinessCustomerContract = selector(
  (state) => state.businessCustomerContracts.current
);
export const currentBusinessCustomerContractSelector = createSelector(
  selectCurrentBusinessCustomerContract
);

export const selectBusinessCustomerContractRules = selector(
  (state) => state.businessCustomerContracts.contractRules
);
export const businessCustomerContractRulesSelector = createSelector(
  selectBusinessCustomerContractRules
);

export const selectBusinessCustomerOrganizationServices = selector(
  (state) => state.businessCustomerContracts.organizationServices
);
export const businessCustomerOrganizationServicesSelector = createSelector(
  selectBusinessCustomerOrganizationServices
);

export const selectBusinessCustomerContractsFilters = selector(
  (state) => state.businessCustomerContracts.businessCustomersContractsFilter
);
export const businessCustomerContractsFiltersSelector = createSelector(
  selectBusinessCustomerContractsFilters
);

export const selectEmployeeAccountsFilters = selector(
  (state) => state.businessCustomerContracts.employeeAccountsFilter
);
export const employeeAccountsFilterSelector = createSelector(
  selectEmployeeAccountsFilters
);

export const selectBusinessCustomerTravelPassDiscounts = selector(
  (state) =>
    state.businessCustomerContracts.businessCustomerContractTravelPassDiscounts
);
export const businessCustomerTravelPassDiscountsSelector = createSelector(
  selectBusinessCustomerTravelPassDiscounts
);

export const selectTravelPassDiscounts = selector(
  (state) => state.businessCustomerContracts.travelPassDiscounts
);
export const travelPassDiscountsSelector = createSelector(
  selectTravelPassDiscounts
);

export const selectSpecialDiscounts = selector(
  (state) => state.businessCustomerContracts.specialDiscounts
);
export const specialDiscountsSelector = createSelector(selectSpecialDiscounts);

export const selectBusinessCustomerSpecialDiscounts = selector(
  (state) =>
    state.businessCustomerContracts.businessCustomerContractSpecialDiscounts
);
export const businessCustomerSpecialDiscountsSelector = createSelector(
  selectBusinessCustomerSpecialDiscounts
);

export const selectEmployeeAccounts = selector(
  (state) => state.businessCustomerContracts.employeeAccounts
);
export const employeeAccountsSelector = createSelector(selectEmployeeAccounts);

export const selectEmployeeAccount = selector(
  (state) => state.businessCustomerContracts.employeeAccount
);
export const employeeAccountSelector = createSelector(selectEmployeeAccount);

export const selectTransactions = selector(
  (state) => state.businessCustomerContracts.transactions
);
export const transactionsSelector = createSelector(selectTransactions);

export const selectTransactionFilters = selector(
  (state) => state.businessCustomerContracts.transactionFilter
);
export const transactionFiltersSelector = createSelector(
  selectTransactionFilters
);

export const selectContractFiles = selector(
  (state) => state.businessCustomerContracts.contractFiles
);
export const contractFilesSelector = createSelector(selectContractFiles);

export const selectLightEmployeeAccounts = selector(
  (state) => state.businessCustomerContracts.employeeAccountsLight
);
export const employeeAccountsLightSelector = createSelector(
  selectLightEmployeeAccounts
);
