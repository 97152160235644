import { createAsyncThunk } from 'store/utils';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import {
  BusinessCustomer,
  BusinessCustomerAddress,
  BusinessCustomerAddressRequest,
  BusinessCustomerBankAccount,
  BusinessCustomerComment,
  BusinessCustomerContactPerson,
  BusinessCustomerContactPersonFilter,
  BusinessCustomerContactPersonRequest,
  BusinessCustomerFilter,
  BusinessCustomerRequest,
  BusinessCustomerUnit,
  BusinessCustomerUnitFilter,
  BusinessCustomerUnitRequest,
  UserForOrganization,
  UserForOrganizationResponse,
} from 'dto/businessCustomer';
import { BusinessCustomerContract } from 'dto/businessCustomerContracts';
import { api } from '@fleet/shared';
import { fetchBusinessCustomers } from 'features/businessCustomer/businessCustomerService';

export const setBusinessCustomersFilter = createAction<
  Partial<BusinessCustomerFilter>
>('businessCustomer/setBusinessCustomerFilter');

export const setBusinessCustomersModalFilter = createAction<
  Partial<BusinessCustomerFilter>
>('businessCustomer/setBusinessCustomersModalFilter');

export const setBusinessCustomerContactPersonFilter = createAction<
  Partial<BusinessCustomerContactPersonFilter>
>('businessCustomer/setBusinessCustomerContactPersonFilter');

export const setBusinessCustomerUnitFilter = createAction<
  Partial<BusinessCustomerUnitFilter>
>('businessCustomer/setBusinessCustomerUnitFilter');

export const setBusinessCustomerContactPerson = createAction<
  BusinessCustomerContactPerson | undefined
>('businessCustomer/setContactPerson');

export const setBusinessCustomerUnit = createAction<
  BusinessCustomerUnit | undefined
>('businessCustomer/setBusinessCustomerUnit');

export const resetBusinessCustomers = createAction(
  'businessCustomer/resetBusinessCustomers'
);

export const resetCurrentBusinessCustomer = createAction(
  'businessCustomer/resetCurrentBusinessCustomer'
);

export const getBusinessCustomers = createAsyncThunk<
  Pagination<BusinessCustomer>,
  Partial<BusinessCustomerFilter> | undefined
>('businessCustomer/getCustomers', async (values, { dispatch, getState }) => {
  if (values) {
    dispatch(setBusinessCustomersFilter(values));
  }

  const { businessCustomersFilter } = getState().businessCustomer;

  return (await fetchBusinessCustomers(businessCustomersFilter)).data;
});

export const getBusinessCustomersForModal = createAsyncThunk<
  Pagination<BusinessCustomer>,
  Partial<BusinessCustomerFilter>
>('businessCustomer/getCustomersForModal', async (filter, { dispatch }) => {
  dispatch(setBusinessCustomersModalFilter(filter));

  return (await fetchBusinessCustomers(filter)).data;
});

export const setBusinessCustomer = createAction<BusinessCustomer>(
  'businessCustomer/setBusinessCustomer'
);

export const getBusinessCustomerById = createAsyncThunk<void, number>(
  'businessCustomer/getCustomer',
  async (id, { dispatch }) => {
    dispatch(
      setBusinessCustomer(
        (await api.get<BusinessCustomer>(`business-customers/${id}`)).data
      )
    );
  }
);

export const getUsersForOrganization = createAsyncThunk<
  Array<UserForOrganization>,
  number
>('businessCustomer/getUsersForOrganization', async (id) => {
  const data = (
    await api.get<Array<UserForOrganizationResponse>>(
      `api/engine/users/for-organization${qs.stringify(
        { organizationId: id },
        {
          skipNulls: true,
          addQueryPrefix: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
      }
    )
  ).data;

  return data.map((user) => ({
    id: user.id,
    fullName: user.full_name,
    isActive: user.is_active,
    agentCode: user.agent_code,
  }));
});

export const updateOrCreateBusinessCustomer = createAsyncThunk<
  BusinessCustomer,
  Partial<BusinessCustomerRequest>
>(
  'businessCustomer/updateOrCreateBusinessCustomer',
  async ({ id, ...payload }) =>
    (
      await (id ? api.put : api.post)(
        `/business-customers${id ? `/${id}` : ''}`,
        payload
      )
    ).data
);

export const getBusinessCustomerContractTab = createAsyncThunk<
  Pagination<BusinessCustomerContract>,
  PaginationParams | undefined
>('businessCustomer/getContracts', async (filter, { getState }) => {
  const { current } = getState().businessCustomer;

  return (
    await api.get(
      `business-customers/contracts${qs.stringify(
        { ...filter, businessCustomerId: current?.id },
        {
          skipNulls: true,
          addQueryPrefix: true,
        }
      )}`
    )
  ).data;
});

export const getBusinessCustomerAddresses = createAsyncThunk<
  Array<BusinessCustomerAddress>
>('businessCustomer/getAddresses', async (_, { getState }) => {
  const { current } = getState().businessCustomer;

  return (
    await api.get(
      `/business-customers/${current?.id}/addresses${qs.stringify(
        { typeId: 'CONTACT_ADDRESS_TYPE.POSTAL' },
        {
          skipNulls: true,
          addQueryPrefix: true,
        }
      )}`
    )
  ).data.items;
});

export const getBusinessCustomerContactPersons = createAsyncThunk<
  Pagination<BusinessCustomerContactPerson>,
  Partial<BusinessCustomerContactPersonFilter> | undefined
>(
  'businessCustomer/getContactPersons',
  async (filter, { dispatch, getState }) => {
    if (filter) {
      dispatch(setBusinessCustomerContactPersonFilter(filter));
    }

    const { contactsFilter, current } = getState().businessCustomer;

    return (
      await api.get<Pagination<BusinessCustomerContactPerson>>(
        `/business-customers/${current?.id}/contact-persons${qs.stringify(
          contactsFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const getBusinessCustomerContactPersonsById = createAsyncThunk<
  BusinessCustomerContactPerson,
  string
>('businessCustomer/getContactPersonsById', async (personId, { getState }) => {
  const { current } = getState().businessCustomer;

  return (
    await api.get(
      `/business-customers/${current?.id}/contact-persons/${personId}`
    )
  ).data;
});

export const updateOrCreateBusinessCustomerContactPerson = createAsyncThunk<
  BusinessCustomerContactPerson,
  BusinessCustomerContactPersonRequest
>(
  'businessCustomer/updateOrCreateBusinessCustomerContactPerson',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomer;

    return (
      await (id ? api.put : api.post)(
        `/business-customers/${current?.id}/contact-persons${
          id ? `/${id}` : ''
        }`,
        rest
      )
    ).data;
  }
);

export const deleteBusinessCustomerContactPerson = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomer/deleteBusinessCustomerContactPersons',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomer;

    return await api.post(
      `/business-customers/${current?.id}/contact-persons/bulk-delete`,
      ids
    );
  }
);

export const getBusinessCustomerUnits = createAsyncThunk<
  Pagination<BusinessCustomerUnit>,
  Partial<BusinessCustomerUnitFilter> | undefined
>('businessCustomer/getUnits', async (filter, { dispatch, getState }) => {
  if (filter) {
    dispatch(setBusinessCustomerUnitFilter(filter));
  }

  const { current, unitsFilter } = getState().businessCustomer;

  return (
    await api.get(
      `/business-customers/${current?.id}/units${qs.stringify(unitsFilter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const updateOrCreateBusinessCustomerUnits = createAsyncThunk<
  Pagination<BusinessCustomerUnit>,
  BusinessCustomerUnitRequest
>(
  'businessCustomer/updateOrCreateBusinessCustomerUnits',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomer;

    return (
      await (id ? api.put : api.post)(
        `/business-customers/${current?.id}/units${id ? `/${id}` : ''}`,
        rest
      )
    ).data;
  }
);

export const getBusinessCustomerUnitsById = createAsyncThunk<
  BusinessCustomerUnit,
  string
>('businessCustomer/getUnitsById', async (unitId, { getState }) => {
  const { current } = getState().businessCustomer;

  return (await api.get(`/business-customers/${current?.id}/units/${unitId}`))
    .data;
});

export const getBusinessCustomerComments = createAsyncThunk<
  Array<BusinessCustomerComment>
>('businessCustomer/getComments', async (_, { getState }) => {
  const { current } = getState().businessCustomer;

  return (await api.get(`/business-customers/${current?.id}/comments`)).data
    .items;
});

export const updateOrCreateBusinessCustomerComments = createAsyncThunk<
  BusinessCustomerComment,
  Omit<BusinessCustomerComment, 'createdOn' | 'createdBy'>
>(
  'businessCustomer/updateOrCreateBusinessCustomerComments',
  async ({ id, ...payload }, { getState }) => {
    const { current } = getState().businessCustomer;

    return (
      await (id ? api.put : api.post)(
        `/business-customers/${current?.id}/comments${id ? `/${id}` : ''}`,
        payload
      )
    ).data;
  }
);

export const deleteBusinessCustomerComments = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomer/deleteBusinessCustomerComments',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomer;

    return await api.post(
      `/business-customers/${current?.id}/comments/bulk-delete`,
      ids
    );
  }
);

export const updateOrCreateBusinessCustomerAddress = createAsyncThunk<
  BusinessCustomerAddress,
  Partial<BusinessCustomerAddressRequest>
>(
  'businessCustomer/updateOrCreateBusinessCustomerAddress',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomer;

    return (
      await (id ? api.put : api.post)(
        `/business-customers/${current?.id}/addresses${id ? `/${id}` : ''}`,
        { ...rest, typeId: 'CONTACT_ADDRESS_TYPE.POSTAL' }
      )
    ).data;
  }
);

export const deleteBusinessCustomerAddresses = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomer/deleteBusinessCustomerAddress',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomer;

    return await api.post(
      `/business-customers/${current?.id}/addresses/bulk-delete`,
      ids
    );
  }
);

export const updateOrCreateBusinessCustomerBankAccounts = createAsyncThunk<
  BusinessCustomerBankAccount,
  BusinessCustomerBankAccount
>(
  'businessCustomer/updateOrCreateBusinessCustomerBankAccount',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomer;

    return (
      await (id ? api.put : api.post)(
        `/business-customers/${current?.id}/bank-accounts${id ? `/${id}` : ''}`,
        rest
      )
    ).data;
  }
);

export const getBusinessCustomerBankAccounts = createAsyncThunk<
  Array<BusinessCustomerBankAccount>
>(
  'businessCustomer/getBusinessCustomerBankAccounts',
  async (_, { getState }) => {
    const { current } = getState().businessCustomer;

    return (await api.get(`/business-customers/${current?.id}/bank-accounts`))
      .data.items;
  }
);

export const deleteBusinessCustomerBankAccounts = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomer/deleteBusinessCustomerBankAccounts',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomer;

    return await api.post(
      `/business-customers/${current?.id}/bank-accounts/bulk-delete`,
      ids
    );
  }
);
