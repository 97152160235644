import React, { ElementType, FC, ReactNode } from 'react';
import { InputLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  label: {
    position: 'relative',
    transform: 'unset',
  },
}));

interface FormLabelProps {
  label?: ReactNode;
  labelPosition?: 'top' | 'left';
  labelComponent?: ElementType;
}

export const FormInputLabel: FC<FormLabelProps> = ({
  label,
  labelComponent = InputLabel,
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.label}
      {...(Boolean(!labelComponent) && { shrink: true })}
      textAlign="left"
      variant="body2"
      gutterBottom
    >
      {label}
    </Typography>
  );
};
