import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { customerProfileReducer } from 'features/customerProfile/customerProfileReducer';
import { loyaltyProgramReducer } from 'features/loyaltyProgram/loyaltyProgramReducer';
import { classificationReducer } from 'features/classification/classificationReducer';
import { businessCustomerReducer } from 'features/businessCustomer/businessCustomerReducer';
import { businessCustomerContractsReducer } from 'features/businessCustomerContracts/businessCustomerContractsReducer';

export default combineReducers({
  classification: classificationReducer,
  common: commonReducer,
  customerProfile: customerProfileReducer,
  loading: loadingReducer,
  loyaltyProgram: loyaltyProgramReducer,
  businessCustomer: businessCustomerReducer,
  businessCustomerContracts: businessCustomerContractsReducer,
});
