import type { FC } from 'react';
import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button, CheckboxField, Icon, TextField, Tooltip } from '@fleet/shared';
import { EmployeeAccount } from 'dto/businessCustomerContracts';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

interface LatestCardSectionProps {
  latestCard?: Partial<EmployeeAccount>;
  unlinkCustomerProfileCard: () => void;
  linkCustomerProfileCard: () => void;
}

export const LatestCardSection: FC<LatestCardSectionProps> = ({
  latestCard,
  unlinkCustomerProfileCard,
  linkCustomerProfileCard,
}) => {
  const [shouldShowCardFields, setShouldShowCardFields] = useState(false);

  const CardHeader = () => (
    <Grid
      container
      sx={{ alignItems: 'center', mt: 3, mb: 3 }}
      spacing={0}
      columns={3}
    >
      <Grid item xs={2}>
        <Typography variant="subtitle" fontWeight="700">
          <TransSubtitle i18nKey="latestCard" />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {latestCard ? (
          <Tooltip
            placement="top"
            content={<TransField i18nKey="existingCardChangeTooltip" />}
          >
            <Typography
              component="span"
              variant="body1"
              color="text.secondary"
              sx={{ float: 'right', cursor: 'pointer' }}
            >
              <Stack direction="row" alignItems="center" columnGap="8px">
                <Icon name="plus" />
                <TransButton i18nKey="addNew" />
              </Stack>
            </Typography>
          </Tooltip>
        ) : (
          <Button
            sx={{ float: 'right' }}
            variant="text"
            startIcon={
              <Icon name={shouldShowCardFields ? 'remove-circle' : 'plus'} />
            }
            onClick={() => {
              if (shouldShowCardFields) {
                setShouldShowCardFields(false);
                linkCustomerProfileCard();
              } else {
                setShouldShowCardFields(true);
                unlinkCustomerProfileCard();
              }
            }}
          >
            <TransButton
              i18nKey={shouldShowCardFields ? 'cancelAddingNewCard' : 'addNew'}
            />
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const CardFields = () => (
    <Grid
      container
      sx={{ alignItems: 'center', mb: 2 }}
      spacing={2}
      columns={3}
    >
      <Grid item xs={1}>
        <TextField
          disabled={!shouldShowCardFields}
          required
          name="customerProfileCard.number"
          label={<TransField i18nKey="cardNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          disabled={!shouldShowCardFields}
          required
          name="customerProfileCard.uid"
          label={<TransField i18nKey="uid" />}
        />
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ width: 'fit-content' }}>
          {!shouldShowCardFields ? (
            <Tooltip
              placement="top"
              content={<TransField i18nKey="existingCardChangeTooltip" />}
            >
              <div>
                <CheckboxField
                  disabled={!shouldShowCardFields}
                  labelPosition="top"
                  name="customerProfileCard.isActive"
                  label={<TransField i18nKey="isActive" />}
                />
              </div>
            </Tooltip>
          ) : (
            <CheckboxField
              disabled={!shouldShowCardFields}
              labelPosition="top"
              name="customerProfileCard.isActive"
              label={<TransField i18nKey="isActive" />}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );

  const NoCardAdded = () => (
    <>
      <CardHeader />
      <Typography variant="caption" fontWeight="700">
        <TransSubtitle i18nKey="noCardAdded" />
      </Typography>
    </>
  );

  return (
    <div>
      {latestCard || shouldShowCardFields ? (
        <>
          <CardHeader />
          <CardFields />
        </>
      ) : (
        <NoCardAdded />
      )}
    </div>
  );
};
