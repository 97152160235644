import React, { FC, useCallback } from 'react';
import { Button, Icon, Modal, formSubmit, useForm } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { CustomerProfile } from 'dto/customerProfile';
import { TransTitle } from 'i18n/trans/title';
import { TransBody } from 'i18n/trans/body';
import { Typography } from '@mui/material';
import {
  deleteCustomerProfile,
  resetCustomerProfileList,
  setCustomerProfileFilter,
} from 'features/customerProfile/customerProfileActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { useDispatch } from 'store/utils';
import { useHistory } from 'react-router-dom';

interface CustomerProfileDeleteModalProps {
  isOpen: boolean;
  customerProfile: CustomerProfile;
  onClose: () => void;
}

export const CustomerProfileDeleteModal: FC<CustomerProfileDeleteModalProps> =
  ({ isOpen, customerProfile, onClose }) => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();
    const { id, firstName, lastName } = customerProfile;

    const onSubmit = useCallback(
      () =>
        formSubmit(async () => {
          await dispatch(deleteCustomerProfile(id)).unwrap();
          dispatch(setCustomerProfileFilter({}));
          dispatch(resetCustomerProfileList());
          alert.success(<TransAlert i18nKey="userDeleted" />);
          onClose();
          history.push('/customer_profiles');
        }),
      [alert, id, dispatch, onClose, history]
    );

    const { handleSubmit, submitting } = useForm({
      onSubmit,
      subscription: { submitting: true },
    });

    return (
      <Modal
        onClose={onClose}
        title={<TransTitle i18nKey="delete" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              startIcon={<Icon name="trash" size={16} />}
              sx={{ backgroundColor: '#DD0005' }}
              variant="contained"
              type="submit"
              disabled={submitting}
            >
              <TransButton i18nKey="delete" />
            </Button>
          </form>
        }
        open={isOpen}
      >
        <Typography paragraph>
          <TransBody
            i18nKey="deleteConfirmation"
            values={{
              firstName,
              lastName,
            }}
          />
        </Typography>
      </Modal>
    );
  };
