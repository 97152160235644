import { createSelector, selector } from 'store/utils';

export const selectBusinessCustomers = selector(
  (state) => state.businessCustomer.list
);
export const businessCustomersSelector = createSelector(
  selectBusinessCustomers
);

export const selectCurrentBusinessCustomer = selector(
  (state) => state.businessCustomer.current
);
export const currentBusinessCustomerSelector = createSelector(
  selectCurrentBusinessCustomer
);

export const selectBusinessCustomersFilter = selector(
  (state) => state.businessCustomer.businessCustomersFilter
);
export const businessCustomersFilterSelector = createSelector(
  selectBusinessCustomersFilter
);

export const selectBusinessCustomerContracts = selector(
  (state) => state.businessCustomer.contracts
);
export const businessCustomerTabContractsSelector = createSelector(
  selectBusinessCustomerContracts
);

export const selectBusinessCustomerAddresses = selector(
  (state) => state.businessCustomer.addresses
);
export const businessCustomerAddressesSelector = createSelector(
  selectBusinessCustomerAddresses
);

export const selectBusinessCustomerComments = selector(
  (state) => state.businessCustomer.comments
);
export const businessCustomerCommentsSelector = createSelector(
  selectBusinessCustomerComments
);

export const selectBusinessCustomerContactPersons = selector(
  (state) => state.businessCustomer.contacts
);
export const businessCustomerContactPersonsSelector = createSelector(
  selectBusinessCustomerContactPersons
);

export const selectBusinessCustomerContactPerson = selector(
  (state) => state.businessCustomer.contact
);
export const businessCustomerContactPersonSelector = createSelector(
  selectBusinessCustomerContactPerson
);

export const selectBusinessCustomerContactPersonFilter = selector(
  (state) => state.businessCustomer.contactsFilter
);
export const businessCustomerContactPersonFilterSelector = createSelector(
  selectBusinessCustomerContactPersonFilter
);

export const selectBusinessCustomerUnitFilter = selector(
  (state) => state.businessCustomer.unitsFilter
);
export const businessCustomerUnitFilterSelector = createSelector(
  selectBusinessCustomerUnitFilter
);

export const selectBusinessCustomerUnits = selector(
  (state) => state.businessCustomer.units
);
export const businessCustomerUnitsSelector = createSelector(
  selectBusinessCustomerUnits
);

export const selectBusinessCustomerUnit = selector(
  (state) => state.businessCustomer.unit
);
export const businessCustomerUnitSelector = createSelector(
  selectBusinessCustomerUnit
);

export const selectUsersForOrganizations = selector(
  (state) => state.businessCustomer.customerManagers
);
export const usersForOrganizationsSelector = createSelector(
  selectUsersForOrganizations
);

export const selectBusinessCustomerBankAccounts = selector(
  (state) => state.businessCustomer.bankAccounts
);
export const businessCustomerBankAccountSelector = createSelector(
  selectBusinessCustomerBankAccounts
);

export const selectBusinessCustomerForModal = selector(
  (state) => state.businessCustomer.businessCustomerModal
);
export const businessCustomerModalSelector = createSelector(
  selectBusinessCustomerForModal
);
export const businessCustomerModalSelectorFilter = selector(
  (state) => state.businessCustomer.businessCustomerModalFilter
);
export const businessCustomerModalSelectorFilterSelector = createSelector(
  businessCustomerModalSelectorFilter
);
