import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  addresses: <Trans i18nKey="title.addresses" defaults="Addresses" />,
  bankAccounts: <Trans i18nKey="title.bankAccounts" defaults="Bank accounts" />,
  businessCustomer: (
    <Trans i18nKey="title.businessCustomer" defaults="Business customer" />
  ),
  businessCustomerContracts: (
    <Trans
      i18nKey="title.businessCustomerContracts"
      defaults="Business customer contracts"
    />
  ),
  comments: <Trans i18nKey="title.comments" defaults="Comments" />,
  connectTravelPasses: (
    <Trans
      i18nKey="title.connectTravelPasses"
      defaults="Connect travel passes"
    />
  ),
  connectVouchers: (
    <Trans i18nKey="title.connectVouchers" defaults="Connect vouchers" />
  ),
  contactPersons: (
    <Trans i18nKey="title.contactPersons" defaults="Contact persons" />
  ),

  contracts: <Trans i18nKey="title.contracts" defaults="Contracts" />,
  customerProfile: (
    <Trans
      i18nKey="title.customerProfile"
      defaults="Customer profile: {{firstName}} {{lastName}}"
    />
  ),
  customerProfileDocument: (
    <Trans
      i18nKey="title.customerProfileDocument"
      defaults="Customer profile"
    />
  ),
  'customerProfileTab.cards': (
    <Trans i18nKey="title.customerProfileTab.cards" defaults="Cards" />
  ),
  'customerProfileTab.consents': (
    <Trans i18nKey="title.customerProfileTab.consents" defaults="Consents" />
  ),

  'customerProfileTab.loyalty': (
    <Trans i18nKey="title.customerProfileTab.loyalty" defaults="Loyalty" />
  ),
  'customerProfileTab.transactions': (
    <Trans
      i18nKey="title.customerProfileTab.transactions"
      defaults="Transactions"
    />
  ),
  'customerProfileTab.travelPasses': (
    <Trans
      i18nKey="title.customerProfileTab.travelPasses"
      defaults="Travel passes"
    />
  ),
  'customerProfileTab.vouchers': (
    <Trans i18nKey="title.customerProfileTab.vouchers" defaults="Vouchers" />
  ),
  delete: <Trans i18nKey="title.delete" defaults="Delete" />,
  discounts: <Trans i18nKey="title.discounts" defaults="Discounts" />,
  employeeAccounts: (
    <Trans i18nKey="title.employeeAccounts" defaults="Employee accounts" />
  ),
  files: <Trans i18nKey="title.files" defaults="Files" />,
  logs: <Trans i18nKey="title.logs" defaults="Logs - {{name}}" />,
  loyaltyId: <Trans i18nKey="title.loyaltyId" defaults="Loyalty ID" />,
  loyaltyProgram: (
    <Trans
      i18nKey="title.loyaltyProgram"
      defaults="Loyalty program: {{name}}"
    />
  ),
  loyaltyProgramDocument: (
    <Trans i18nKey="title.loyaltyProgramDocument" defaults="Loyalty program" />
  ),
  loyaltyTargetGroups: (
    <Trans i18nKey="title.loyaltyTargetGroups" defaults="Target groups" />
  ),
  newImport: <Trans i18nKey="title.newImport" defaults="New import" />,
  rulesAndSettings: (
    <Trans i18nKey="title.rulesAndSettings" defaults="Rules and settings" />
  ),
  searchCustomerProfiles: (
    <Trans
      i18nKey="title.searchCustomerProfiles"
      defaults="Search customer profiles"
    />
  ),
  specialDiscountSearch: (
    <Trans
      i18nKey="title.specialDiscountSearch"
      defaults="Special discount search"
    />
  ),
  tab: <Trans i18nKey="title.tab" defaults="{{tab}}" />,
  tier: <Trans i18nKey="title.tier" defaults="Tier" />,
  totalPoints: <Trans i18nKey="title.totalPoints" defaults="Total points" />,
  transactions: <Trans i18nKey="title.transactions" defaults="Transactions" />,
  travelPassDiscountSearch: (
    <Trans
      i18nKey="title.travelPassDiscountSearch"
      defaults="Travel pass discount search"
    />
  ),
  units: <Trans i18nKey="title.units" defaults="Units" />,
  unusedPoints: <Trans i18nKey="title.unusedPoints" defaults="Unused points" />,
});
