import { Loadable, Table, TableColumns } from '@fleet/shared';
import { CustomerProfileConsentHistory } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import { useCallback, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { getCustomerProfileConsentHistory } from 'features/customerProfile/customerProfileActions';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { customerConsentsHistorySelector } from 'features/customerProfile/customerProfileSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { customerConsentsHistoryTableLoadingSelector } from 'features/loading/loadingSelectors';

interface CustomerProfileConsentHistoryProps {}

export const Consents: FC<CustomerProfileConsentHistoryProps> = () => {
  const consents = useSelector(customerConsentsHistorySelector);
  const data = useMemo(() => consents?.items ?? [], [consents]);
  const loading = useSelector(customerConsentsHistoryTableLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProfileConsentHistory());
  }, [dispatch]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (consents) {
        const { limit = pageSize, offset } = consents;
        return offset / limit;
      }
      return 0;
    },
    [consents]
  );

  const columns = useMemo<TableColumns<CustomerProfileConsentHistory>>(
    () => [
      {
        id: 'name',
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        id: 'decision.name',
        accessor: ({ decision }) => decision?.name,
        Header: <TransTableHead i18nKey="decision" />,
      },
      {
        id: 'createdOn.value',
        accessor: ({ createdOn }) =>
          formatDate(createdOn?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getCustomerProfileConsentHistory(paginationParams)),
    [dispatch]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: consents?.totalCount,
    },
    usePagination,
    useRowSelect
  );

  return (
    <Loadable loading={loading}>
      <Table table={table} />
    </Loadable>
  );
};
