import { createSelector, selector } from 'store/utils';

export const selectCustomerProfiles = selector(
  (state) => state.customerProfile.list
);
export const customerProfilesSelector = createSelector(selectCustomerProfiles);

export const selectCustomerProfileFilter = selector(
  (state) => state.customerProfile.filter
);
export const customerProfileFilterSelector = createSelector(
  selectCustomerProfileFilter
);

export const selectCurrenCustomerProfile = selector(
  (state) => state.customerProfile.current
);
export const currentCustomerProfileSelector = createSelector(
  selectCurrenCustomerProfile
);

export const selectCustomerTransactions = selector(
  (state) => state.customerProfile.transactions
);
export const customerTransactionsSelector = createSelector(
  selectCustomerTransactions
);

export const selectCustomerConsentsHistory = selector(
  (state) => state.customerProfile.consents
);
export const customerConsentsHistorySelector = createSelector(
  selectCustomerConsentsHistory
);

export const selectCustomerLoyaltyTargetGroups = selector(
  (state) => state.customerProfile.loyaltyTargetGroups
);
export const customerLoyaltyTargetGroupSelector = createSelector(
  selectCustomerLoyaltyTargetGroups
);

export const selectCustomerProfileCards = selector(
  (state) => state.customerProfile.cards
);
export const customerProfileCardsSelector = createSelector(
  selectCustomerProfileCards
);

export const selectCustomerProfileTravelPasses = selector(
  (state) => state.customerProfile.customerTravelPasses
);
export const customerProfileTravelPassesSelector = createSelector(
  selectCustomerProfileTravelPasses
);

export const selectTravelPasses = selector(
  (state) => state.customerProfile.travelPasses
);
export const travelPassesSelector = createSelector(selectTravelPasses);

export const selectTravelPassFilter = selector(
  (state) => state.customerProfile.travelPassFilter
);
export const travelPassFilterSelector = createSelector(selectTravelPassFilter);

export const selectCustomerProfileVouchers = selector(
  (state) => state.customerProfile.customerVouchers
);
export const customerProfileVouchersSelector = createSelector(
  selectCustomerProfileVouchers
);

export const selectVouchers = selector(
  (state) => state.customerProfile.vouchers
);
export const vouchersSelector = createSelector(selectVouchers);

export const selectCustomerProfileVoucherBalance = selector(
  (state) => state.customerProfile.customerVoucherBalance
);
export const customerProfileVoucherBalanceSelector = createSelector(
  selectCustomerProfileVoucherBalance
);

export const selectVouchersFilter = selector(
  (state) => state.customerProfile.vouchersFilter
);
export const vouchersFilterSelector = createSelector(selectVouchersFilter);

export const selectCustomerProfileVoucherFilter = selector(
  (state) => state.customerProfile.customerProfileVoucherFilter
);
export const customerProfileVoucherFilterSelector = createSelector(
  selectCustomerProfileVoucherFilter
);

export const selectCustomerProfileCardFilter = selector(
  (state) => state.customerProfile.customerProfileCardFilter
);
export const customerProfileCardFilterSelector = createSelector(
  selectCustomerProfileCardFilter
);

export const selectCustomerProfileTravelPassFilter = selector(
  (state) => state.customerProfile.customerProfileTravelPassFilter
);
export const customerProfileTravelPassFilterSelector = createSelector(
  selectCustomerProfileTravelPassFilter
);

export const selectCustomerProfileLight = selector(
  (state) => state.customerProfile.customerProfilesLight
);

export const customerProfileLightListSelector = createSelector(
  selectCustomerProfileLight
);
