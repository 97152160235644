import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  activeCardPresent: (
    <Trans
      i18nKey="alert.activeCardPresent"
      defaults="You may have only one active card at a time."
    />
  ),
  businessCustomerAddressCreated: (
    <Trans
      i18nKey="alert.businessCustomerAddressCreated"
      defaults="Business customer address created successfully."
    />
  ),
  businessCustomerAddressRemoved: (
    <Trans
      i18nKey="alert.businessCustomerAddressRemoved"
      defaults="Business customer address updated successfully."
    />
  ),
  businessCustomerAddressUpdated: (
    <Trans
      i18nKey="alert.businessCustomerAddressUpdated"
      defaults="Business customer address updated successfully."
    />
  ),
  businessCustomerBankAccountCreated: (
    <Trans
      i18nKey="alert.businessCustomerBankAccountCreated"
      defaults="Business customer bank account created successfully."
    />
  ),
  businessCustomerBankAccountRemoved: (
    <Trans
      i18nKey="alert.businessCustomerBankAccountRemoved"
      defaults="Business customer bank account updated successfully."
    />
  ),
  businessCustomerBankAccountUpdated: (
    <Trans
      i18nKey="alert.businessCustomerBankAccountUpdated"
      defaults="Business customer bank account updated successfully."
    />
  ),
  businessCustomerCommentCreated: (
    <Trans
      i18nKey="alert.businessCustomerCommentCreated"
      defaults="Business customer comment created successfully."
    />
  ),
  businessCustomerCommentRemoved: (
    <Trans
      i18nKey="alert.businessCustomerCommentRemoved"
      defaults="Business customer comment updated successfully."
    />
  ),
  businessCustomerCommentUpdated: (
    <Trans
      i18nKey="alert.businessCustomerCommentUpdated"
      defaults="Business customer comment updated successfully."
    />
  ),
  businessCustomerContactProfileCreated: (
    <Trans
      i18nKey="alert.businessCustomerContactProfileCreated"
      defaults="Business customer contact profile created successfully."
    />
  ),
  businessCustomerContactProfileDeleted: (
    <Trans
      i18nKey="alert.businessCustomerContactProfileDeleted"
      defaults="Business customer contact profile deleted successfully."
    />
  ),
  businessCustomerContactProfileUpdated: (
    <Trans
      i18nKey="alert.businessCustomerContactProfileUpdated"
      defaults="Business customer contact profile updated successfully."
    />
  ),
  businessCustomerContractCreated: (
    <Trans
      i18nKey="alert.businessCustomerContractCreated"
      defaults="Business customer contract created successfully."
    />
  ),
  businessCustomerContractRulesUpdated: (
    <Trans
      i18nKey="alert.businessCustomerContractRulesUpdated"
      defaults="Business customer contract rules and settings updated successfully."
    />
  ),
  businessCustomerContractUpdated: (
    <Trans
      i18nKey="alert.businessCustomerContractUpdated"
      defaults="Business customer contract updated successfully."
    />
  ),
  businessCustomerCreated: (
    <Trans
      i18nKey="alert.businessCustomerCreated"
      defaults="Business customer created successfully."
    />
  ),
  businessCustomerSpecialDiscountCreated: (
    <Trans
      i18nKey="alert.businessCustomerSpecialDiscountCreated"
      defaults="Business customer special discount created successfully."
    />
  ),
  businessCustomerSpecialDiscountRemoved: (
    <Trans
      i18nKey="alert.businessCustomerSpecialDiscountRemoved"
      defaults="Business customer special discount updated successfully."
    />
  ),
  businessCustomerSpecialDiscountUpdated: (
    <Trans
      i18nKey="alert.businessCustomerSpecialDiscountUpdated"
      defaults="Business customer special discount updated successfully."
    />
  ),
  businessCustomerTravelPassDiscountCreated: (
    <Trans
      i18nKey="alert.businessCustomerTravelPassDiscountCreated"
      defaults="Business customer travel pass discount created successfully."
    />
  ),

  businessCustomerTravelPassDiscountRemoved: (
    <Trans
      i18nKey="alert.businessCustomerTravelPassDiscountRemoved"
      defaults="Business customer travel pass discount removed successfully."
    />
  ),
  businessCustomerTravelPassDiscountUpdated: (
    <Trans
      i18nKey="alert.businessCustomerTravelPassDiscountUpdated"
      defaults="Business customer travel pass discount updated successfully."
    />
  ),
  businessCustomerUnitCreated: (
    <Trans
      i18nKey="alert.businessCustomerUnitCreated"
      defaults="Business customer unit created successfully."
    />
  ),
  businessCustomerUnitRemoved: (
    <Trans
      i18nKey="alert.businessCustomerUnitRemoved"
      defaults="Business customer unit updated successfully."
    />
  ),
  businessCustomerUnitUpdated: (
    <Trans
      i18nKey="alert.businessCustomerUnitUpdated"
      defaults="Business customer unit updated successfully."
    />
  ),
  businessCustomerUpdated: (
    <Trans
      i18nKey="alert.businessCustomerUpdated"
      defaults="Business customer updated successfully."
    />
  ),
  contractFileCreated: (
    <Trans
      i18nKey="alert.contractFileCreated"
      defaults="Contract file created successfully."
    />
  ),
  contractFileRemoved: (
    <Trans
      i18nKey="alert.contractFileRemoved"
      defaults="Contract file updated successfully."
    />
  ),
  contractFileUpdated: (
    <Trans
      i18nKey="alert.contractFileUpdated"
      defaults="Contract file updated successfully."
    />
  ),
  customerProfileCreated: (
    <Trans i18nKey="alert.customerProfileCreated" defaults="User created." />
  ),
  customerProfileLinkNeeded: (
    <Trans
      i18nKey="alert.customerProfileLinkNeeded"
      defaults="You need to link customer profile to save an employee account."
    />
  ),
  customerProfileUpdated: (
    <Trans i18nKey="alert.customerProfileUpdated" defaults="User updated." />
  ),
  employeeAccountCreated: (
    <Trans
      i18nKey="alert.employeeAccountCreated"
      defaults="Employee account created successfully."
    />
  ),
  employeeAccountRemoved: (
    <Trans
      i18nKey="alert.employeeAccountRemoved"
      defaults="Employee account removed successfully."
    />
  ),
  employeeAccountUpdated: (
    <Trans
      i18nKey="alert.employeeAccountUpdated"
      defaults="Employee account updated successfully."
    />
  ),
  passwordResetLink: (
    <Trans
      i18nKey="alert.passwordResetLink"
      defaults="Password reset link sent to your email."
    />
  ),
  travelPassConnected: (
    <Trans
      i18nKey="alert.travelPassConnected"
      defaults='Travel pass connected to customer profile "{{firstName}} {{lastName}}".'
    />
  ),
  userDeleted: <Trans i18nKey="alert.userDeleted" defaults="User deleted." />,
  voucherConnected: (
    <Trans
      i18nKey="alert.voucherConnected"
      defaults='Voucher connected to customer profile "{{firstName}} {{lastName}}".'
    />
  ),
});
