import { createReducer } from '@reduxjs/toolkit';
import {
  DistanceConversionRate,
  DistanceConversionRateException,
  ImportDetails,
  Line,
  LoyaltyParameters,
  LoyaltyTier,
  PlaceClass,
  TargetGroup,
  TransactionConversionRate,
} from 'dto/loyaltyProgram';
import {
  getActiveLines,
  getDistanceConversionRateExceptions,
  getDistanceConversionRates,
  getLoyaltyParameters,
  getLoyaltyPointsImportList,
  getLoyaltyProgramTiersWithLocalizations,
  getLoyaltyTierLocalizations,
  getPlaceClasses,
  getTargetGroupLocalizations,
  getTargetGroupsWithLocalizations,
  getTransactionConversionRates,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import { Pagination } from '@fleet/shared/dto/pagination';

interface LoyaltyProgramState {
  parameters?: LoyaltyParameters;
  placeClasses: Array<PlaceClass>;
  distanceConversionRates: Array<DistanceConversionRate>;
  transactionConversionRates: Array<TransactionConversionRate>;
  distanceConversionRateExceptions: Array<DistanceConversionRateException>;
  lines: Array<Line>;
  targetGroups: Array<TargetGroup>;
  loyaltyPointsImportList?: Pagination<ImportDetails>;
  loyaltyTiers: Array<LoyaltyTier>;
}

const initialState: LoyaltyProgramState = {
  placeClasses: [],
  distanceConversionRates: [],
  distanceConversionRateExceptions: [],
  transactionConversionRates: [],
  lines: [],
  targetGroups: [],
  loyaltyTiers: [],
};

export const loyaltyProgramReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPlaceClasses.fulfilled, (state, action) => {
      state.placeClasses = action.payload;
    })
    .addCase(getTransactionConversionRates.fulfilled, (state, action) => {
      state.transactionConversionRates = action.payload;
    })
    .addCase(getDistanceConversionRates.fulfilled, (state, action) => {
      state.distanceConversionRates = action.payload;
    })
    .addCase(getDistanceConversionRateExceptions.fulfilled, (state, action) => {
      state.distanceConversionRateExceptions = action.payload;
    })
    .addCase(getActiveLines.fulfilled, (state, action) => {
      state.lines = action.payload;
    })
    .addCase(getLoyaltyParameters.fulfilled, (state, action) => {
      state.parameters = action.payload;
    })
    .addCase(getTargetGroupsWithLocalizations.fulfilled, (state, action) => {
      state.targetGroups = action.payload;
    })
    .addCase(getTargetGroupLocalizations.fulfilled, (state, action) => {
      state.targetGroups = state.targetGroups.map((targetGroup) => {
        if (targetGroup.id !== action.meta.arg) {
          return targetGroup;
        }

        return { ...targetGroup, localizations: action.payload };
      });
    })
    .addCase(
      getLoyaltyProgramTiersWithLocalizations.fulfilled,
      (state, action) => {
        state.loyaltyTiers = action.payload;
      }
    )
    .addCase(getLoyaltyTierLocalizations.fulfilled, (state, action) => {
      state.loyaltyTiers = state.loyaltyTiers.map((tier) => {
        if (tier.id !== action.meta.arg) {
          return tier;
        }

        return { ...tier, localizations: action.payload };
      });
    })
    .addCase(getLoyaltyPointsImportList.fulfilled, (state, action) => {
      state.loyaltyPointsImportList = action.payload;
    });
});
