import { TabPanel, Tabs } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { Cards } from 'routes/customerProfile/tabs/Cards';
import { Consents } from 'routes/customerProfile/tabs/Consents';
import { Loyalty } from 'routes/customerProfile/tabs/Loyalty';
import { Transactions } from 'routes/customerProfile/tabs/Transactions';
import { TravelPasses } from 'routes/customerProfile/tabs/TravelPasses';
import { Vouchers } from 'routes/customerProfile/tabs/Vouchers';
import { TransTitle } from 'i18n/trans/title';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '24px',
    },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

const customerProfileTabs = [
  'loyalty',
  'vouchers',
  'transactions',
  'travelPasses',
  'cards',
  'consents',
] as const;

export const CustomerProfileTabs = () => {
  const classes = useStyles();

  const tabs = useMemo(
    () => ({
      loyalty: <Loyalty />,
      vouchers: <Vouchers />,
      transactions: <Transactions />,
      travelPasses: <TravelPasses />,
      cards: <Cards />,
      consents: <Consents />,
    }),
    []
  );

  return (
    <>
      <div className={classes.root}>
        <Tabs>
          {customerProfileTabs.map((tab) => (
            <TabPanel
              key={tab}
              label={<TransTitle i18nKey={`customerProfileTab.${tab}`} />}
              value={tab}
            >
              {tabs[tab]}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
};
