import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransHeader = createTrans({
  businessCustomer: (
    <Trans i18nKey="header.businessCustomer" defaults="Business customer" />
  ),
  businessCustomerContractEdit: (
    <Trans
      i18nKey="header.businessCustomerContractEdit"
      defaults="Business customer contract nr {{number}}:"
    />
  ),
  businessCustomerContractNew: (
    <Trans
      i18nKey="header.businessCustomerContractNew"
      defaults="New business customer contract"
    />
  ),
  businessCustomerContracts: (
    <Trans
      i18nKey="header.businessCustomerContracts"
      defaults="Business customer contracts"
    />
  ),
  businessCustomerEdit: (
    <Trans
      i18nKey="header.businessCustomerEdit"
      defaults="Business customer: {{name}}"
    />
  ),
  businessCustomerNew: (
    <Trans
      i18nKey="header.businessCustomerNew"
      defaults="New business customer"
    />
  ),
  customerProfile: (
    <Trans i18nKey="header.customerProfile" defaults="Customer profiles" />
  ),
  customers: <Trans i18nKey="header.customers" defaults="Customers" />,
  loyaltyProgram: (
    <Trans i18nKey="header.loyaltyProgram" defaults="Loyalty program" />
  ),
  newBusinessCustomer: (
    <Trans
      i18nKey="header.newBusinessCustomer"
      defaults="New business customer"
    />
  ),
  newBusinessCustomerContract: (
    <Trans
      i18nKey="header.newBusinessCustomerContract"
      defaults="New business customer contract"
    />
  ),
});
