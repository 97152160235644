import { useDispatch, useSelector } from 'store/utils';
import { useEffect } from 'react';
import {
  getCustomerProfile,
  getCustomerProfileLoyaltyTargetGroups,
} from 'features/customerProfile/customerProfileActions';
import { useParams } from 'react-router-dom';
import { CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { CustomerProfileTabs } from 'routes/customerProfile/CustomerProfileTabs';
import { currentCustomerProfileSelector } from 'features/customerProfile/customerProfileSelectors';
import { CustomerProfileForm } from 'routes/customerProfile/CustomerProfileForm';
import { customerProfileEditLoadingSelector } from 'features/loading/loadingSelectors';

export const CustomerProfileEdit = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const profile = useSelector(currentCustomerProfileSelector);
  const loading = useSelector(customerProfileEditLoadingSelector);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerProfile(Number(id)));
      dispatch(getCustomerProfileLoyaltyTargetGroups(Number(id)));
    }
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <div>
        <Layout
          header={
            <CardHeader
              title={
                <TransTitle
                  i18nKey="customerProfile"
                  values={{
                    firstName: profile?.firstName,
                    lastName: profile?.lastName,
                  }}
                />
              }
            />
          }
        >
          <CustomerProfileForm />
        </Layout>
      </div>
      <CustomerProfileTabs />
    </Loadable>
  );
};
