import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Modal, Table, TableColumns, api } from '@fleet/shared';
import { ImportDetails, ImportLog } from 'dto/loyaltyProgram';
import { TransTitle } from 'i18n/trans/title';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { useTable } from 'react-table';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';

interface ImportDetailsModalProps {
  details?: ImportDetails;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  scrollableContent: {
    '& .MuiDialogContent-root': {
      maxHeight: '300px',
    },
  },
}));

export const ImportDetailsModal: FC<ImportDetailsModalProps> = ({
  details,
  onClose,
}) => {
  const [logs, setLogs] = useState<Array<ImportLog>>([]);
  const classes = useStyles();

  const fetchLogs = useCallback(async () => {
    const { logs } = (
      await api.get<{ importId: string; logs: Array<ImportLog> }>(
        `/loyalty-programs/points/imports/${details?.id}/logs`
      )
    ).data;
    setLogs(logs);
  }, [details?.id]);

  useEffect(() => {
    if (details) {
      fetchLogs();
    }
  }, [details, fetchLogs]);

  const columns = useMemo<TableColumns<ImportLog>>(
    () => [
      {
        id: 'logType.name',
        accessor: ({ logType: { name } }) =>
          name[0].toUpperCase() + name.slice(1),
        Header: <TransTableHead i18nKey="type" />,
        width: 50,
      },
      {
        accessor: 'message',
        Header: <TransTableHead i18nKey="message" />,
        width: 200,
      },
      {
        id: 'createdOn',
        accessor: ({ createdOn }) =>
          formatDate(createdOn, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
    ],
    []
  );

  const table = useTable<ImportLog>({
    data: logs,
    columns,
  });

  return (
    <Modal
      className={classes.scrollableContent}
      open={!!details}
      onClose={onClose}
      title={<TransTitle i18nKey="logs" values={{ name: details?.fileName }} />}
      actionButton={
        <Button
          startIcon={<Icon name="refresh" />}
          onClick={fetchLogs}
          disabled={
            logs.length > 0 &&
            logs[logs.length - 1].logType.id === 'LOG_TYPE.END'
          }
        >
          <TransButton i18nKey="refresh" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Table table={table} />
    </Modal>
  );
};
