import { createAsyncThunk } from 'store/utils';
import { Pagination } from '@fleet/shared/dto/pagination';
import qs from 'qs';

import { createAction } from '@reduxjs/toolkit';
import {
  BusinessCustomerContract,
  BusinessCustomerContractRules,
  BusinessCustomerContractRulesRequest,
  BusinessCustomerContractsFilter,
  BusinessCustomerContractSpecialDiscount,
  BusinessCustomerContractSpecialDiscountRequest,
  BusinessCustomerContractTravelPassDiscount,
  BusinessCustomerContractTravelPassDiscountRequest,
  ContractDiscount,
  ContractDiscountSearchFilter,
  ContractFile,
  EmployeeAccount,
  EmployeeAccountRequest,
  EmployeeAccountsFilter,
  OrganizationService,
  Transaction,
  TransactionFilter,
} from 'dto/businessCustomerContracts';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';

export const setBusinessCustomerContractsFilter = createAction<
  Partial<BusinessCustomerContractsFilter>
>('businessCustomerContracts/setBusinessCustomerContractsFilter');

export const setBusinessCustomerTransactionFilter = createAction<
  Partial<TransactionFilter>
>('businessCustomerContracts/setBusinessCustomerTransactionFilter');

export const setContractDiscountsFilter = createAction<
  Partial<ContractDiscountSearchFilter>
>('businessCustomerContracts/setTravelPassDiscountsFilter');

export const setEmployeeAccountsFilter = createAction<
  Partial<EmployeeAccountsFilter>
>('businessCustomerContracts/setEmployeeAccountsFilter');

export const resetTravelPassDiscountsSearchTable = createAction(
  'businessCustomerContracts/resetTravelPassDiscountsSearchTable'
);

export const resetSpecialDiscountsSearchTable = createAction(
  'businessCustomerContracts/resetSpecialDiscountsSearchTable'
);

export const setEmployeeAccount = createAction<EmployeeAccount | undefined>(
  'businessCustomer/setEmployeeAccount'
);

export const setEmployeeAccounts = createAction<
  Pagination<EmployeeAccount> | undefined
>('businessCustomer/setEmployeeAccounts');

export const resetBusinessCustomerContracts = createAction(
  'businessCustomerContracts/resetBusinessCustomerContracts'
);

export const resetCurrentBusinessCustomerContract = createAction(
  'businessCustomerContracts/resetCurrentBusinessCustomerContract'
);

export const getBusinessCustomerContracts = createAsyncThunk<
  Pagination<BusinessCustomerContract>,
  Partial<BusinessCustomerContractsFilter> | undefined
>(
  'businessCustomerContracts/getBusinessCustomerContracts',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setBusinessCustomerContractsFilter(values));
    }

    const { businessCustomersContractsFilter } =
      getState().businessCustomerContracts;

    return (
      await api.get(
        `business-customers/contracts${qs.stringify(
          businessCustomersContractsFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
            encode: false,
          }
        )}`
      )
    ).data;
  }
);

export const getTransactions = createAsyncThunk<
  Pagination<Transaction>,
  Partial<TransactionFilter>
>(
  'businessCustomerContracts/getTransactions',
  async (filter, { dispatch, getState }) => {
    dispatch(setBusinessCustomerTransactionFilter(filter));

    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `/contracts/${current?.id}/transactions${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
          arrayFormat: 'comma',
          encode: false,
        })}`
      )
    ).data;
  }
);

export const getBusinessCustomerContractById = createAsyncThunk<
  BusinessCustomerContract,
  { businessCustomerId: number; contractId: number }
>(
  'businessCustomerContracts/getBusinessCustomerContractById',
  async ({ businessCustomerId, contractId }) =>
    (
      await api.get(
        `business-customers/${businessCustomerId}/contracts/${contractId}`
      )
    ).data
);

export const updateOrCreateBusinessCustomerContracts = createAsyncThunk<
  BusinessCustomerContract,
  { payload: FormData } & { businessCustomerId?: number; id?: number }
>(
  'businessCustomerContracts/updateOrCreateBusinessCustomerContract',
  async ({ businessCustomerId, id, payload }) =>
    (
      await (id ? api.put : api.post)(
        `/business-customers/${businessCustomerId}/contracts${
          id ? `/${id}` : ''
        }`,
        payload
      )
    ).data
);

export const getBusinessCustomerContractRules =
  createAsyncThunk<BusinessCustomerContractRules>(
    'businessCustomerContracts/getBusinessCustomerContractRules',
    async (_, { getState }) => {
      const { current } = getState().businessCustomerContracts;

      return (await api.get(`/contracts/${current?.id}/rules`)).data;
    }
  );

export const updateBusinessCustomerContractRules = createAsyncThunk<
  BusinessCustomerContractRules,
  BusinessCustomerContractRulesRequest
>(
  'businessCustomerContracts/updateBusinessCustomerContractRules',
  async (payload, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (await api.put(`/contracts/${current?.id}/rules`, payload)).data;
  }
);

export const getOrganizationServices = createAsyncThunk<
  Array<OrganizationService>,
  number
>(
  'businessCustomerContracts/getOrganizationServices',
  async (organizationId) =>
    (await api.get(`/organizations/${organizationId}/add-ons`)).data.items
);

export const getBusinessCustomerTravelPassDiscounts = createAsyncThunk<
  Array<BusinessCustomerContractTravelPassDiscount>,
  boolean | undefined
>(
  'businessCustomerContracts/getBusinessCustomerTravelPassDiscounts',
  async (isActive = true, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `/contracts/${current?.id}/travel-pass-discounts${qs.stringify(
          { isActive },
          {
            addQueryPrefix: true,
          }
        )}`
      )
    ).data.items;
  }
);

export const updateOrCreateBusinessCustomerTravelDiscount = createAsyncThunk<
  BusinessCustomerContractTravelPassDiscount,
  BusinessCustomerContractTravelPassDiscountRequest
>(
  'businessCustomerContracts/updateOrCreateBusinessCustomerTravelDiscount',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await (id ? api.put : api.post)(
        `/contracts/${current?.id}/travel-pass-discounts${id ? `/${id}}` : ''}`,
        rest
      )
    ).data;
  }
);

export const deleteBusinessCustomerTravelPassDiscounts = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomerContracts/deleteBusinessCustomerTravelPassDiscounts',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return await api.post(
      `/contracts/${current?.id}/travel-pass-discounts/bulk-delete`,
      ids
    );
  }
);

export const getTravelPassDiscounts = createAsyncThunk<
  Array<ContractDiscount>,
  Partial<ContractDiscountSearchFilter>
>(
  'businessCustomerContracts/getTravelPassDiscounts',
  async (filter, { dispatch, getState }) => {
    dispatch(setContractDiscountsFilter(filter));

    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `/organizations/${
          current?.contractor.id
        }/travel-pass-discounts${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data.items;
  }
);

export const getBusinessCustomerSpecialDiscounts = createAsyncThunk<
  Array<BusinessCustomerContractSpecialDiscount>,
  boolean | undefined
>(
  'businessCustomerContracts/getBusinessCustomerSpecialDiscounts',
  async (isActive = true, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `/contracts/${current?.id}/special-discounts?isActive=${isActive}`
      )
    ).data.items;
  }
);

export const updateOrCreateBusinessCustomerSpecialDiscount = createAsyncThunk<
  BusinessCustomerContractSpecialDiscount,
  BusinessCustomerContractSpecialDiscountRequest
>(
  'businessCustomerContracts/updateOrCreateBusinessCustomerSpecialDiscount',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await (id ? api.put : api.post)(
        `/contracts/${current?.id}/special-discounts${id ? `/${id}` : ''}`,
        rest
      )
    ).data;
  }
);

export const deleteBusinessCustomerSpecialDiscounts = createAsyncThunk<
  void,
  Array<number>
>(
  'businessCustomerContracts/deleteBusinessCustomerSpecialDiscounts',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return await api.post(
      `/contracts/${current?.id}/special-discounts/bulk-delete`,
      ids
    );
  }
);

export const getSpecialDiscounts = createAsyncThunk<
  Array<ContractDiscount>,
  Partial<ContractDiscountSearchFilter>
>(
  'businessCustomerContracts/getSpecialDiscounts',
  async (filter, { dispatch, getState }) => {
    dispatch(setContractDiscountsFilter(filter));

    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `/organizations/${
          current?.contractor.id
        }/special-discounts${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data.items;
  }
);

export const getEmployeeAccounts = createAsyncThunk<
  Pagination<EmployeeAccount>,
  Partial<EmployeeAccountsFilter> | undefined
>(
  'businessCustomerContracts/getEmployeeAccounts',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setEmployeeAccountsFilter(values));
    }

    const { employeeAccountsFilter, current } =
      getState().businessCustomerContracts;

    return (
      await api.get(
        `contracts/${current?.id}/employee-accounts${qs.stringify(
          employeeAccountsFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const getEmployeeAccountsLight = createAsyncThunk<
  Array<Classifier<number>>
>(
  'businessCustomerContracts/getEmployeeAccountsLight',
  async (_, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (await api.get(`contracts/${current?.id}/employee-accounts-light`))
      .data.items;
  }
);

export const deactivateEmployeeAccounts = createAsyncThunk<void, Array<number>>(
  'businessCustomerContracts/deactivateEmployeeAccounts',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return await api.post(
      `/contracts/${current?.id}/employee-accounts/bulk-deactivate`,
      ids
    );
  }
);

export const getEmployeeAccountById = createAsyncThunk<EmployeeAccount, string>(
  'businessCustomerContracts/getEmployeeAccountById',
  async (employeeAccountId, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await api.get(
        `contracts/${current?.id}/employee-accounts/${employeeAccountId}`
      )
    ).data;
  }
);

export const updateOrCreateEmployeeAccount = createAsyncThunk<
  EmployeeAccount,
  EmployeeAccountRequest
>(
  'businessCustomerContracts/updateOrCreateEmployeeAccount',
  async ({ id, ...rest }, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await (id ? api.put : api.post)(
        `/contracts/${current?.id}/employee-accounts${id ? `/${id}` : ''}`,
        rest
      )
    ).data;
  }
);

export const getContractFiles = createAsyncThunk<Array<ContractFile>>(
  'businessCustomerContracts/getContractFiles',
  async (_, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (await api.get(`/contracts/${current?.id}/files`)).data.items;
  }
);

export const updateOrCreateContractFile = createAsyncThunk<
  ContractFile,
  { payload: FormData } & { id?: number }
>(
  'businessCustomerContracts/updateOrCreateContractFile',
  async ({ id, payload }, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return (
      await (id ? api.put : api.post)(
        `/contracts/${current?.id}/files${id ? `/${id}` : ''}`,
        payload,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
    ).data;
  }
);

export const deleteContractFiles = createAsyncThunk<void, Array<number>>(
  'businessCustomerContracts/deleteContractFiles',
  async (ids, { getState }) => {
    const { current } = getState().businessCustomerContracts;

    return await api.post(`/contracts/${current?.id}/files/bulk-delete`, ids);
  }
);
