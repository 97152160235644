import { createSelector, selector } from 'store/utils';
import { ClassificationGroup } from 'dto/classification';

export const selectClassifications = selector((state) => state.classification);
export const classificationsSelector = createSelector(selectClassifications);

export const selectBusinessEntities = selector(
  (state) => state.classification[ClassificationGroup.BUSINESS_ENTITY]
);
export const businessEntitiesSelector = createSelector(selectBusinessEntities);

export const selectCountries = selector(
  (state) => state.classification[ClassificationGroup.COUNTRY]
);
export const countriesSelector = createSelector(selectCountries);
