import { BusinessCustomerContractsFilter } from 'dto/businessCustomerContracts';
import { api } from '@fleet/shared';
import qs from 'qs';
import { saveAs } from 'file-saver';
import { getFormattedDate } from 'helpers/getFormattedDate';

export const getContractReport = async (
  filter?: Partial<BusinessCustomerContractsFilter>
) => {
  const response = await api.get(
    `business-customers/contracts/report${qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
      arrayFormat: 'comma',
    })}`,
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'text/csv',
  });
  const url = window.URL.createObjectURL(blob);

  saveAs(url, `contracts_report_${getFormattedDate()}`);
};

export const getBaseDataFile = async ({
  fileId,
  fileName,
}: {
  fileId: string;
  fileName: string;
}) => {
  const response = await api.get(`/files/${fileId}`, {
    baseURL: process.env.REACT_APP_API_BD,
    responseType: 'blob',
  });

  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  const url = window.URL.createObjectURL(blob);

  saveAs(url, fileName);
};
