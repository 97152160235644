import { FC, useState } from 'react';
import { ToggleButtonGroup } from '@fleet/shared';
import { TravelPassDiscountsTable } from 'routes/customers/businessCustomerContracts/tabs/Discounts/TravelPassDiscountsTable';
import { SpecialDiscountsTable } from 'routes/customers/businessCustomerContracts/tabs/Discounts/SpecialDiscountsTable';
import { TransField } from 'i18n/trans/field';

const OPTIONS = [
  {
    label: <TransField i18nKey="specialDiscounts" />,
    value: 'first',
  },
  {
    label: <TransField i18nKey="travelPassDiscounts" />,
    value: 'second',
  },
];

export const DiscountsTable: FC = () => {
  const [isTravelPassDiscount, setIsTravelPassDiscount] = useState(false);

  return (
    <>
      <ToggleButtonGroup
        value={isTravelPassDiscount ? OPTIONS[1].value : OPTIONS[0].value}
        options={OPTIONS}
        onChange={() => setIsTravelPassDiscount(!isTravelPassDiscount)}
        color="secondary"
      />
      {isTravelPassDiscount ? (
        <TravelPassDiscountsTable />
      ) : (
        <SpecialDiscountsTable />
      )}
    </>
  );
};
