import { createLoadingSelector } from 'store/utils';
import {
  createCustomerProfile,
  createCustomerProfileCard,
  createCustomerProfileTravelPass,
  createCustomerProfileVoucher,
  deleteCustomerProfile,
  deleteCustomerProfileCard,
  deleteCustomerProfileTravelPass,
  deleteCustomerProfileVouchers,
  getCustomerProfile,
  getCustomerProfileCards,
  getCustomerProfileConsentHistory,
  getCustomerProfileList,
  getCustomerProfileListLight,
  getCustomerProfileLoyaltyTargetGroups,
  getCustomerProfileTransactions,
  getCustomerProfileTravelPasses,
  getCustomerProfileVoucherBalance,
  getCustomerProfileVouchers,
  getTravelPasses,
  getVouchers,
  sendPasswordReset,
  updateCustomerProfile,
  updateCustomerProfileCard,
} from 'features/customerProfile/customerProfileActions';
import {
  deleteLoyaltyProgramTierLocalizations,
  getActiveLines,
  getDistanceConversionRateExceptions,
  getDistanceConversionRates,
  getLoyaltyPointsImportList,
  getLoyaltyProgramTiersWithLocalizations,
  getLoyaltyTierLocalizations,
  getPlaceClasses,
  getTargetGroupsWithLocalizations,
  getTransactionConversionRates,
  updateLoyaltyProgramTier,
  updateOrCreateLoyaltyProgramTierLocalization,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  deleteBusinessCustomerAddresses,
  deleteBusinessCustomerBankAccounts,
  deleteBusinessCustomerComments,
  deleteBusinessCustomerContactPerson,
  getBusinessCustomerAddresses,
  getBusinessCustomerBankAccounts,
  getBusinessCustomerById,
  getBusinessCustomerComments,
  getBusinessCustomerContactPersons,
  getBusinessCustomerContactPersonsById,
  getBusinessCustomerContractTab,
  getBusinessCustomers,
  getBusinessCustomerUnits,
  getBusinessCustomerUnitsById,
  getUsersForOrganization,
  updateOrCreateBusinessCustomer,
  updateOrCreateBusinessCustomerAddress,
  updateOrCreateBusinessCustomerBankAccounts,
  updateOrCreateBusinessCustomerComments,
  updateOrCreateBusinessCustomerContactPerson,
  updateOrCreateBusinessCustomerUnits,
} from 'features/businessCustomer/businessCustomerActions';
import {
  deactivateEmployeeAccounts,
  deleteBusinessCustomerSpecialDiscounts,
  deleteBusinessCustomerTravelPassDiscounts,
  deleteContractFiles,
  getBusinessCustomerContractById,
  getBusinessCustomerContractRules,
  getBusinessCustomerContracts,
  getBusinessCustomerSpecialDiscounts,
  getBusinessCustomerTravelPassDiscounts,
  getContractFiles,
  getEmployeeAccountById,
  getEmployeeAccounts,
  getEmployeeAccountsLight,
  getOrganizationServices,
  getSpecialDiscounts,
  getTransactions,
  getTravelPassDiscounts,
  updateBusinessCustomerContractRules,
  updateOrCreateBusinessCustomerContracts,
  updateOrCreateBusinessCustomerSpecialDiscount,
  updateOrCreateBusinessCustomerTravelDiscount,
  updateOrCreateContractFile,
  updateOrCreateEmployeeAccount,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';

export const customerProfileTableLoadingSelector = createLoadingSelector(
  getCustomerProfileList
);

export const customerTransactionsTableLoadingSelector = createLoadingSelector(
  getCustomerProfileTransactions
);

export const customerTravelPassTableLoadingSelector = createLoadingSelector(
  getCustomerProfileTravelPasses,
  createCustomerProfileTravelPass,
  deleteCustomerProfileTravelPass
);

export const travelPassSearchTableLoadingSelector = createLoadingSelector(
  getTravelPasses,
  createCustomerProfileTravelPass
);

export const customerVouchersTableLoadingSelector = createLoadingSelector(
  getCustomerProfileVouchers,
  createCustomerProfileVoucher,
  deleteCustomerProfileVouchers,
  getCustomerProfileVoucherBalance
);

export const vouchersSearchTableLoadingSelector =
  createLoadingSelector(getVouchers);

export const customerConsentsHistoryTableLoadingSelector =
  createLoadingSelector(getCustomerProfileConsentHistory);

export const customerProfileEditLoadingSelector = createLoadingSelector(
  getCustomerProfile,
  getCustomerProfileCards,
  updateCustomerProfileCard,
  deleteCustomerProfileCard,
  createCustomerProfileCard,
  deleteCustomerProfile,
  updateCustomerProfile,
  createCustomerProfile,
  sendPasswordReset,
  getCustomerProfileLoyaltyTargetGroups
);

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const distanceConversionRatesLoadingSelector = createLoadingSelector(
  getPlaceClasses,
  getDistanceConversionRates,
  getDistanceConversionRateExceptions,
  getActiveLines
);

export const transactionConversionRatesLoadingSelector = createLoadingSelector(
  getTransactionConversionRates
);

export const targetGroupsLoadingSelector = createLoadingSelector(
  getTargetGroupsWithLocalizations
);

export const pointsImportLoadingSelector = createLoadingSelector(
  getLoyaltyPointsImportList
);

export const loyaltyProgramTiersLoadingSelector = createLoadingSelector(
  getLoyaltyProgramTiersWithLocalizations,
  updateLoyaltyProgramTier,
  getLoyaltyTierLocalizations,
  updateOrCreateLoyaltyProgramTierLocalization,
  deleteLoyaltyProgramTierLocalizations
);

export const businessCustomerEditViewLoadingSelector = createLoadingSelector(
  updateOrCreateBusinessCustomer,
  getBusinessCustomerById
);

export const businessCustomersTableLoadingSelector =
  createLoadingSelector(getBusinessCustomers);

export const businessCustomerTabsLoadingSelector = createLoadingSelector(
  getBusinessCustomerContractTab,
  getBusinessCustomerAddresses,
  updateOrCreateBusinessCustomerContracts,
  updateOrCreateBusinessCustomerAddress,
  deleteBusinessCustomerAddresses,
  getBusinessCustomerComments,
  updateOrCreateBusinessCustomerComments,
  deleteBusinessCustomerComments,
  getBusinessCustomerContactPersons,
  deleteBusinessCustomerContactPerson,
  getBusinessCustomerUnits,
  deleteBusinessCustomerBankAccounts,
  updateOrCreateBusinessCustomerBankAccounts,
  getBusinessCustomerBankAccounts
);

export const businessCustomerContactPersonLoadingSelector =
  createLoadingSelector(
    getBusinessCustomerContactPersonsById,
    updateOrCreateBusinessCustomerContactPerson
  );

export const businessCustomerUnitsLoadingSelector = createLoadingSelector(
  getBusinessCustomerUnitsById,
  updateOrCreateBusinessCustomerUnits
);

export const businessCustomerFormCustomerManagersLoadingSelector =
  createLoadingSelector(getUsersForOrganization);

export const businessCustomerContractsTableLoadingSelector =
  createLoadingSelector(getBusinessCustomerContracts);

export const businessCustomerContractEditViewLoadingSelector =
  createLoadingSelector(
    getBusinessCustomerContractById,
    updateOrCreateBusinessCustomerContracts
  );

export const businessCustomerContractTabsLoadingSelector =
  createLoadingSelector(
    getBusinessCustomerContractRules,
    getOrganizationServices,
    updateBusinessCustomerContractRules,
    getBusinessCustomerTravelPassDiscounts,
    updateOrCreateBusinessCustomerTravelDiscount,
    deleteBusinessCustomerTravelPassDiscounts,
    getBusinessCustomerSpecialDiscounts,
    updateOrCreateBusinessCustomerSpecialDiscount,
    deleteBusinessCustomerSpecialDiscounts,
    deactivateEmployeeAccounts,
    getEmployeeAccounts,
    getEmployeeAccountsLight,
    getContractFiles,
    updateOrCreateContractFile,
    deleteContractFiles
  );

export const businessCustomerContractTransactionsTableLoadingSelector =
  createLoadingSelector(getTransactions);

export const travelPassDiscountsModalLoadingSelector = createLoadingSelector(
  getTravelPassDiscounts,
  getSpecialDiscounts
);

export const employeeAccountEditViewLoadingSelector = createLoadingSelector(
  getEmployeeAccountById,
  updateOrCreateEmployeeAccount
);

export const findCustomerProfilesLoadingSelector = createLoadingSelector(
  getCustomerProfileListLight
);
