import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  advancedSearch: (
    <Trans i18nKey="button.advancedSearch" defaults="Advanced search" />
  ),
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  cancelAddingNewCard: (
    <Trans
      i18nKey="button.cancelAddingNewCard"
      defaults="Cancel adding new card"
    />
  ),
  cancelLinkingExistingProfile: (
    <Trans
      i18nKey="button.cancelLinkingExistingProfile"
      defaults="Cancel linking existing profile"
    />
  ),
  cancelLinkingNewCustomer: (
    <Trans
      i18nKey="button.cancelLinkingNewCustomer"
      defaults="Cancel linking new customer"
    />
  ),
  changesSaved: (
    <Trans i18nKey="button.changesSaved" defaults="All changes saved" />
  ),
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  connectTravelPasses: (
    <Trans
      i18nKey="button.connectTravelPasses"
      defaults="Connect travel passes"
    />
  ),
  connectVouchers: (
    <Trans i18nKey="button.connectVouchers" defaults="Connect vouchers" />
  ),
  create: <Trans i18nKey="button.create" defaults="Create" />,
  createNewProfile: (
    <Trans i18nKey="button.createNewProfile" defaults="Create new profile" />
  ),
  deactivate: <Trans i18nKey="button.deactivate" defaults="Deactivate" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  exportSelected: <Trans i18nKey="button.exportSelected" defaults="Export" />,
  linkExistingProfile: (
    <Trans
      i18nKey="button.linkExistingProfile"
      defaults="Link existing profile"
    />
  ),
  newImport: <Trans i18nKey="button.newImport" defaults="New import" />,
  passwordReset: (
    <Trans i18nKey="button.passwordReset" defaults="Send password reset link" />
  ),
  refresh: <Trans i18nKey="button.refresh" defaults="Refresh" />,
  removeTravelPasses: (
    <Trans
      i18nKey="button.removeTravelPasses"
      defaults="Remove travel passes"
    />
  ),
  removeVouchers: (
    <Trans i18nKey="button.removeVouchers" defaults="Remove vouchers" />
  ),
  reset: <Trans i18nKey="button.reset" defaults="Send password reset link" />,
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  submit: <Trans i18nKey="button.submit" defaults="Submit" />,
  unlinkCustomerProfile: (
    <Trans
      i18nKey="button.unlinkCustomerProfile"
      defaults="Unlink customer profile"
    />
  ),
});
