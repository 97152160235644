import { TabPanel, Tabs } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { FC, useMemo } from 'react';
import { DistanceConversionRates } from 'routes/loyaltyProgram/tabs/DistanceConversionRates';
import { TargetGroups } from 'routes/loyaltyProgram/tabs/TargetGroups';
import { Tiers } from 'routes/loyaltyProgram/tabs/Tiers/Tiers';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { PointsImport } from 'routes/loyaltyProgram/tabs/PointsImport/PointsImport';
import { TransactionConversionRates } from 'routes/loyaltyProgram/tabs/TransactionConversionRates';
import { useSelector } from 'store/utils';
import { loyaltyParametersSelector } from 'features/loyaltyProgram/loyaltyProgramSelectors';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '24px',
    },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

export const LoyaltyConfigurations = () => {
  const classes = useStyles();
  const loyaltyParameters = useSelector(loyaltyParametersSelector);

  const tabs: Array<[TransSubtitleKeys, FC]> = useMemo(
    () => [
      ['targetGroups', TargetGroups],
      ['tiers', Tiers],
      [
        'conversionRates',
        loyaltyParameters?.pointsConversionRule.id ===
        'LOYALTY_PROGRAM_POINTS_CONVERSION_RULE.TRANSACTION_COST'
          ? TransactionConversionRates
          : DistanceConversionRates,
      ],
      ['pointsImport', PointsImport],
    ],
    [loyaltyParameters?.pointsConversionRule.id]
  );

  return (
    <div className={classes.root}>
      <Tabs>
        {tabs.map(([key, Component]) => (
          <TabPanel
            key={key}
            label={<TransSubtitle i18nKey={key} />}
            value={key}
          >
            <Component />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
