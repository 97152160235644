import React, { FC, useEffect } from 'react';
import { BusinessCustomerForm } from './BusinessCustomerForm';
import { CardHeader } from '@fleet/shared/mui';
import { Layout, Loadable } from '@fleet/shared';
import { ContactPersonsDrawerForm } from './tabs/ContactPersons/ContactPersonsDrawerForm';
import { UnitsDrawerForm } from './tabs/Units/UnitsDrawerForm';
import { BusinessCustomerTabs } from 'routes/customers/businessCustomer/BusinessCustomerTabs';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerEditViewLoadingSelector } from 'features/loading/loadingSelectors';
import { currentBusinessCustomerSelector } from 'features/businessCustomer/businessCustomerSelectors';
import { Route, Switch, useParams } from 'react-router-dom';
import {
  getBusinessCustomerById,
  resetCurrentBusinessCustomer,
} from 'features/businessCustomer/businessCustomerActions';
import { TransHeader } from 'i18n/trans/header';

export const BusinessCustomerDetails: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(businessCustomerEditViewLoadingSelector);
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(getBusinessCustomerById(+id));
    }

    return () => {
      dispatch(resetCurrentBusinessCustomer());
    };
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <div>
        <Layout
          header={
            <CardHeader
              title={
                businessCustomer ? (
                  <TransHeader
                    i18nKey="businessCustomerEdit"
                    values={{
                      name: businessCustomer?.name,
                    }}
                  />
                ) : (
                  <TransHeader i18nKey="businessCustomerNew" />
                )
              }
            />
          }
        >
          <BusinessCustomerForm />
        </Layout>
      </div>
      <Switch>
        <Route
          path="/customers/business_customers/edit/:id/contacts/:action(create|edit)/:entityId?"
          component={ContactPersonsDrawerForm}
          exact
        />
        <Route
          path="/customers/business_customers/edit/:id/units/:action(create|edit)/:entityId?"
          component={UnitsDrawerForm}
          exact
        />
      </Switch>
      {businessCustomer && <BusinessCustomerTabs />}
    </Loadable>
  );
};
