import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  accountNumber: (
    <Trans i18nKey="table.head.accountNumber" defaults="Account number" />
  ),
  activated: <Trans i18nKey="table.head.activated" defaults="Activated" />,
  active: <Trans i18nKey="table.head.active" defaults="Active" />,
  address: <Trans i18nKey="table.head.address" defaults="Address" />,
  arrivalTime: (
    <Trans i18nKey="table.head.arrivalTime" defaults="Arrival time" />
  ),
  availableLimit: (
    <Trans i18nKey="table.head.availableLimit" defaults="Available limit" />
  ),
  bankName: <Trans i18nKey="table.head.bankName" defaults="Bank name" />,
  businessCustomer: (
    <Trans i18nKey="table.head.businessCustomer" defaults="Business customer" />
  ),
  calculationType: (
    <Trans i18nKey="table.head.calculationType" defaults="Calculation type" />
  ),
  cancelled: <Trans i18nKey="table.head.cancelled" defaults="Cancelled" />,
  carbonRate: (
    <Trans
      i18nKey="table.head.carbonRate"
      defaults="CO₂ rate (distance unit)"
    />
  ),
  cardNumber: <Trans i18nKey="table.head.cardNumber" defaults="Card number" />,
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="table.head.city" defaults="City/Parish" />,
  comment: <Trans i18nKey="table.head.comment" defaults="Comment" />,
  contractNumber: (
    <Trans i18nKey="table.head.contractNumber" defaults="Contract number" />
  ),
  contractor: <Trans i18nKey="table.head.contractor" defaults="Contractor" />,
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  createdBy: <Trans i18nKey="table.head.createdBy" defaults="Created by" />,
  createdOn: <Trans i18nKey="table.head.createdOn" defaults="Created on" />,
  creditLimit: (
    <Trans i18nKey="table.head.creditLimit" defaults="Credit limit" />
  ),
  creditUsedLimit: (
    <Trans i18nKey="table.head.creditUsedLimit" defaults="Used limit" />
  ),
  currency: <Trans i18nKey="table.head.currency" defaults="Currency" />,
  dailyLimit: <Trans i18nKey="table.head.dailyLimit" defaults="Daily limit" />,

  decision: <Trans i18nKey="table.head.decision" defaults="Decision" />,
  departureStop: (
    <Trans i18nKey="table.head.departureStop" defaults="Departure stop" />
  ),
  departureTime: (
    <Trans i18nKey="table.head.departureTime" defaults="Departure time" />
  ),
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  destinationStop: (
    <Trans i18nKey="table.head.destinationStop" defaults="Destination stop" />
  ),
  discountName: (
    <Trans i18nKey="table.head.discountName" defaults="Discount name" />
  ),
  distanceRate: (
    <Trans
      i18nKey="table.head.distanceRate"
      defaults="Distance rate (distance unit)"
    />
  ),
  email: <Trans i18nKey="table.head.email" defaults="Email address" />,
  employeeAccountName: (
    <Trans
      i18nKey="table.head.employeeAccountName"
      defaults="Employee account name"
    />
  ),
  endDate: <Trans i18nKey="table.head.endDate" defaults="End date" />,
  errors: <Trans i18nKey="table.head.errors" defaults="Errors" />,
  file: <Trans i18nKey="table.head.file" defaults="File" />,
  fileType: <Trans i18nKey="table.head.fileType" defaults="File type" />,

  firstName: <Trans i18nKey="table.head.firstName" defaults="First name" />,
  iban: <Trans i18nKey="table.head.iban" defaults="IBAN" />,
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  lastName: <Trans i18nKey="table.head.lastName" defaults="Last name" />,
  localizations: (
    <Trans i18nKey="table.head.localizations" defaults="Localizations" />
  ),
  loyalty: (
    <Trans i18nKey="table.head.loyalty" defaults="Joined loyalty program" />
  ),
  loyaltyPoints: (
    <Trans i18nKey="table.head.loyaltyPoints" defaults="Loyalty points" />
  ),
  message: <Trans i18nKey="table.head.message" defaults="Message" />,
  monthlyCreditLimit: (
    <Trans
      i18nKey="table.head.monthlyCreditLimit"
      defaults="Monthly credit limit"
    />
  ),
  monthlyLimit: (
    <Trans i18nKey="table.head.monthlyLimit" defaults="Monthly limit" />
  ),
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  nominalValue: (
    <Trans i18nKey="table.head.nominalValue" defaults="Nominal value" />
  ),
  number: <Trans i18nKey="table.head.number" defaults="Number" />,
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  permanentCreditLimit: (
    <Trans
      i18nKey="table.head.permanentCreditLimit"
      defaults="Permanent credit limit"
    />
  ),
  phone: <Trans i18nKey="table.head.phone" defaults="Phone number" />,
  placeClasses: (
    <Trans i18nKey="table.head.placeClasses" defaults="Place classes" />
  ),
  pointsFrom: <Trans i18nKey="table.head.pointsFrom" defaults="Points from" />,
  pointsTo: <Trans i18nKey="table.head.pointsTo" defaults="Points to" />,
  position: <Trans i18nKey="table.head.position" defaults="Position" />,
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  productName: (
    <Trans i18nKey="table.head.productName" defaults="Product name" />
  ),
  purchasedOn: (
    <Trans i18nKey="table.head.purchasedOn" defaults="Purchased on" />
  ),
  rate: <Trans i18nKey="table.head.rate" defaults="Rate" />,
  referenceNumber: (
    <Trans i18nKey="table.head.referenceNumber" defaults="Reference number" />
  ),
  registrationCode: (
    <Trans i18nKey="table.head.registrationCode" defaults="Registration code" />
  ),
  specialDiscount: (
    <Trans i18nKey="table.head.specialDiscount" defaults="Special discount" />
  ),
  startDate: <Trans i18nKey="table.head.startDate" defaults="Start date" />,
  status: <Trans i18nKey="table.head.status" defaults="Status" />,
  street: <Trans i18nKey="table.head.street" defaults="Street/house number" />,
  swift: <Trans i18nKey="table.head.swift" defaults="SWIFT" />,
  ticketNumber: (
    <Trans i18nKey="table.head.ticketNumber" defaults="Ticket number" />
  ),
  totalRows: <Trans i18nKey="table.head.totalRows" defaults="Total rows" />,
  transactionNumber: (
    <Trans
      i18nKey="table.head.transactionNumber"
      defaults="Transaction number"
    />
  ),
  transactionType: (
    <Trans i18nKey="table.head.transactionType" defaults="Transaction type" />
  ),
  travelPassDiscount: (
    <Trans
      i18nKey="table.head.travelPassDiscount"
      defaults="Travel pass discount"
    />
  ),
  travelPassNumber: (
    <Trans
      i18nKey="table.head.travelPassNumber"
      defaults="Travel pass number"
    />
  ),
  travelPassType: (
    <Trans i18nKey="table.head.travelPassType" defaults="Travel pass type" />
  ),
  travelProduct: (
    <Trans i18nKey="table.head.travelProduct" defaults="Travel product" />
  ),
  trip: <Trans i18nKey="table.head.trip" defaults="Trip" />,
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  unit: <Trans i18nKey="table.head.unit" defaults="Unit" />,
  usedAmount: <Trans i18nKey="table.head.usedAmount" defaults="Used amount" />,
  usedLimit: <Trans i18nKey="table.head.usedLimit" defaults="Used limit" />,
  usedPaymentsMax: (
    <Trans
      i18nKey="table.head.usedPaymentsMax"
      defaults="Used payments / max"
    />
  ),
  usedTicketsMax: (
    <Trans i18nKey="table.head.usedTicketsMax" defaults="Used tickets / max" />
  ),
  valid: <Trans i18nKey="table.head.valid" defaults="Valid" />,
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
  validUntil: <Trans i18nKey="table.head.validUntil" defaults="Valid until" />,
  voucherCode: (
    <Trans i18nKey="table.head.voucherCode" defaults="Voucher code" />
  ),
  voucherType: (
    <Trans i18nKey="table.head.voucherType" defaults="Voucher type" />
  ),
  zipCode: <Trans i18nKey="table.head.zipCode" defaults="Zip code" />,
});
