import { createReducer } from '@reduxjs/toolkit';
import { setCurrentBusinessEntity } from 'features/common/commonActions';

interface CommonState {
  currentBusinessEntityId: number;
}

const initialState: CommonState = {
  currentBusinessEntityId: Number(localStorage.getItem('businessEntityId')),
};

export const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCurrentBusinessEntity, (state, action) => {
      localStorage.setItem('businessEntityId', String(action.payload));
      state.currentBusinessEntityId = action.payload;
    });
});
