import React, { FC, useCallback, useState } from 'react';
import { Button, Icon, Loadable, Modal, useForm } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { ConnectTravelPassSearchForm } from 'routes/customerProfile/ConnectTravelPassSearchForm';
import { ConnectTravelPassTable } from 'routes/customerProfile/ConnectTravelPassTable';
import { Row } from 'react-table';
import { TravelPass } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import {
  createCustomerProfileTravelPass,
  getCustomerProfileTravelPasses,
} from 'features/customerProfile/customerProfileActions';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { currentCustomerProfileSelector } from 'features/customerProfile/customerProfileSelectors';
import { travelPassSearchTableLoadingSelector } from 'features/loading/loadingSelectors';

interface ConnectTravelPassModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ConnectTravelPassModal: FC<ConnectTravelPassModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [selectedRows, setSelectedRows] = useState<Row<TravelPass>[]>([]);
  const alert = useAlert();
  const dispatch = useDispatch();
  const loading = useSelector(travelPassSearchTableLoadingSelector);
  const customerProfile = useSelector(currentCustomerProfileSelector);

  const onSubmit = useCallback(async () => {
    const travelPassIds = selectedRows.map((row) => row.original.id);

    await dispatch(createCustomerProfileTravelPass(travelPassIds))
      .unwrap()
      .then(() => {
        alert.success(
          <TransAlert
            i18nKey="travelPassConnected"
            values={{
              firstName: customerProfile?.firstName,
              lastName: customerProfile?.lastName,
            }}
          />
        );
        dispatch(getCustomerProfileTravelPasses());
        onClose();
      })
      .catch(() => {
        onClose();
        dispatch(getCustomerProfileTravelPasses());
      });
  }, [
    alert,
    dispatch,
    selectedRows,
    customerProfile?.firstName,
    customerProfile?.lastName,
    onClose,
  ]);

  const { handleSubmit, submitting } = useForm({
    onSubmit,
    subscription: { submitting: true },
  });

  const onSelectedRowsChange = (rows: Row<TravelPass>[]) => {
    setSelectedRows(rows);
  };

  return (
    <Loadable loading={loading}>
      <Modal
        onClose={onClose}
        title={<TransTitle i18nKey="connectTravelPasses" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              startIcon={<Icon name="check" size={16} />}
              variant="contained"
              type="submit"
              disabled={!selectedRows.length || submitting}
            >
              <TransButton i18nKey="confirm" />
            </Button>
          </form>
        }
        open={isOpen}
      >
        <ConnectTravelPassSearchForm />
        <ConnectTravelPassTable onSelectedRowsChange={onSelectedRowsChange} />
      </Modal>
    </Loadable>
  );
};
