import { FC } from 'react';
import { Chip, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

type MultiChipProps = {
  items?: Array<string>;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    '&&.MuiChip-root': {
      height: 20,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      fontSize: '10px',
      borderRadius: '3px',
    },
  },
  container: {
    padding: '6px 8px 6px 8px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
}));

export const MultiChip: FC<MultiChipProps> = ({ items }) => {
  const classes = useStyles();

  if (!items || !items.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Stack direction="row" columnGap="4px">
        {items.map((item, index) => (
          <Chip className={classes.chip} label={item} key={index} />
        ))}
      </Stack>
    </div>
  );
};
