import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  accountName: <Trans i18nKey="field.accountName" defaults="Account name" />,
  accountNumber: (
    <Trans i18nKey="field.accountNumber" defaults="Account number" />
  ),
  addOnsAllowedRule: (
    <Trans
      i18nKey="field.addOnsAllowedRule"
      defaults="Allow purchase of add-ons"
    />
  ),
  all: <Trans i18nKey="field.all" defaults="All" />,
  allowedComfortLevels: (
    <Trans
      i18nKey="field.allowedComfortLevels"
      defaults="Allowed comfort levels"
    />
  ),
  allowedPassengerTypes: (
    <Trans
      i18nKey="field.allowedPassengerTypes"
      defaults="Allowed passenger types"
    />
  ),
  arePointsUsableOnlyWhenTicketHasCheckedIn: (
    <Trans
      i18nKey="field.arePointsUsableOnlyWhenTicketHasCheckedIn"
      defaults="Points are usable after check in"
    />
  ),
  availableLimit: (
    <Trans i18nKey="field.availableLimit" defaults="Available limit" />
  ),

  birthdate: <Trans i18nKey="field.birthdate" defaults="Birth date" />,
  businessCustomerName: (
    <Trans
      i18nKey="field.businessCustomerName"
      defaults="Business customer name"
    />
  ),
  calculationType: (
    <Trans i18nKey="field.calculationType" defaults="Calculation type" />
  ),
  card: <Trans i18nKey="field.card" defaults="Card number" />,
  cardNumber: <Trans i18nKey="field.cardNumber" defaults="Card number" />,
  city: <Trans i18nKey="field.city" defaults="City/Parish" />,
  clientManager: (
    <Trans i18nKey="field.clientManager" defaults="Customer manager" />
  ),
  clientName: <Trans i18nKey="field.clientName" defaults="Client name" />,
  comment: <Trans i18nKey="field.comment" defaults="Comment" />,
  concessionId: <Trans i18nKey="field.concessionId" defaults="Concession ID" />,
  concessionType: (
    <Trans i18nKey="field.concessionType" defaults="Concession type" />
  ),
  contactPerson: (
    <Trans i18nKey="field.contactPerson" defaults="Contact person" />
  ),
  contactPersonSince: (
    <Trans i18nKey="field.contactPersonSince" defaults="Contact person since" />
  ),
  contactPersonUntil: (
    <Trans i18nKey="field.contactPersonUntil" defaults="Contact person until" />
  ),
  contractNumber: (
    <Trans i18nKey="field.contractNumber" defaults="Contract number" />
  ),
  contractType: <Trans i18nKey="field.contractType" defaults="Contract type" />,
  contractor: <Trans i18nKey="field.contractor" defaults="Contractor" />,
  corporateClientAccount: (
    <Trans
      i18nKey="field.corporateClientAccount"
      defaults="Corporate client account"
    />
  ),
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  createDate: <Trans i18nKey="field.createDate" defaults="Date created" />,

  creditLimit: <Trans i18nKey="field.creditLimit" defaults="Credit limit" />,
  creditType: <Trans i18nKey="field.creditType" defaults="Credit type" />,

  currency: <Trans i18nKey="field.currency" defaults="Currency" />,
  customerUnit: <Trans i18nKey="field.customerUnit" defaults="Customer unit" />,
  dailyCreditLimit: (
    <Trans i18nKey="field.dailyCreditLimit" defaults="Daily credit limit" />
  ),
  departureTimeFrom: (
    <Trans i18nKey="field.departureTimeFrom" defaults="Departure time from" />
  ),
  departureTimeTo: (
    <Trans i18nKey="field.departureTimeTo" defaults="Departure time to" />
  ),
  dietaryRequirements: (
    <Trans
      i18nKey="field.diateryRequirements"
      defaults="Dietary requirements"
    />
  ),
  discountName: <Trans i18nKey="field.discountName" defaults="Discount name" />,
  email: <Trans i18nKey="field.email" defaults="Email address" />,
  employeeAccountName: (
    <Trans
      i18nKey="field.employeeAccountName"
      defaults="Employee account name"
    />
  ),
  endDateFromTo: (
    <Trans i18nKey="field.endDateFromTo" defaults="End date(from* - to)" />
  ),
  existingCardChangeTooltip: (
    <Trans
      i18nKey="field.existingCardChangeTooltip"
      defaults="An existing card can only be changed from customer profile card section"
    />
  ),
  file: <Trans i18nKey="field.file" defaults="File" />,
  findCustomerProfileToLink: (
    <Trans
      i18nKey="field.findCustomerProfileToLink"
      defaults="Find customer profile to link"
    />
  ),
  finished: <Trans i18nKey="field.finished" defaults="Finished" />,
  firstName: <Trans i18nKey="field.firstName" defaults="First name" />,
  gender: <Trans i18nKey="field.gender" defaults="Gender" />,
  giftCardsAllowedRule: (
    <Trans
      i18nKey="field.giftCardsAllowedRule"
      defaults="Allow purchase of gift vouchers"
    />
  ),
  groupTicketAllowedRule: (
    <Trans
      i18nKey="field.groupTicketAllowedRule"
      defaults="Allow purchase of group tickets"
    />
  ),
  inProgress: <Trans i18nKey="field.inProgress" defaults="In progress" />,
  internationalJourneyRule: (
    <Trans
      i18nKey="field.internationalJourneyRule"
      defaults="Allow purchase of international level"
    />
  ),
  invoiceEmail: <Trans i18nKey="field.invoiceEmail" defaults="Invoice email" />,
  isActive: <Trans i18nKey="field.isActive" defaults="Is Active" />,
  isCompanyAccountManager: (
    <Trans
      i18nKey="field.isCompanyAccountManager"
      defaults="Is company's account manager"
    />
  ),
  isCorporateCustomerProfileAllowedToJoinLoyaltyProgram: (
    <Trans
      i18nKey="field.isCorporateCustomerProfileAllowedToJoinLoyaltyProgram"
      defaults="Corporate customer profile is allowed to join loyalty program"
    />
  ),
  lastName: <Trans i18nKey="field.lastName" defaults="Last name" />,
  limitFrom: <Trans i18nKey="field.limitFrom" defaults="Limit from" />,
  limitTo: <Trans i18nKey="field.limitTo" defaults="Limit to" />,
  loyalty: <Trans i18nKey="field.loyalty" defaults="Joined loyalty program" />,
  loyaltyId: <Trans i18nKey="field.loyaltyId" defaults="Loyalty Id" />,
  loyaltyVoucherServiceId: (
    <Trans
      i18nKey="field.loyaltyVoucherServiceId"
      defaults="Loyalty program voucher service"
    />
  ),
  maxNumberOfTicketsInBooking: (
    <Trans
      i18nKey="field.maxNumberOfTicketsInBooking"
      defaults="Max number of tickets in booking"
    />
  ),
  monthlyCreditLimit: (
    <Trans i18nKey="field.monthlyCreditLimit" defaults="Monthly credit limit" />
  ),
  name: <Trans i18nKey="field.name" defaults="Name" />,
  owner: <Trans i18nKey="field.owner" defaults="Owner" />,
  paymentTerm: (
    <Trans i18nKey="field.paymentTerm" defaults="Payment term (in days)" />
  ),
  permanentCreditLimit: (
    <Trans
      i18nKey="field.permanentCreditLimit"
      defaults="Permanent credit limit"
    />
  ),
  personalCode: <Trans i18nKey="field.personalCode" defaults="Personal code" />,
  phone: <Trans i18nKey="field.phone" defaults="Phone number" />,
  points: <Trans i18nKey="field.points" defaults="Points" />,
  pointsAssignedOnBirthday: (
    <Trans
      i18nKey="field.pointsAssignedOnBirthday"
      defaults="Points assigned on birthday"
    />
  ),

  pointsAssignedOnJoining: (
    <Trans
      i18nKey="field.pointsAssignedOnJoining"
      defaults="Points assigned on joining"
    />
  ),
  pointsAssigningRule: (
    <Trans
      i18nKey="field.pointsAssigningRule"
      defaults="Rule for assigning points"
    />
  ),
  pointsConversionRule: (
    <Trans
      i18nKey="field.pointsConversionRule"
      defaults="Loyalty points conversion rule"
    />
  ),
  pointsFromDisabledTooltip: (
    <Trans
      i18nKey="field.pointsFrom"
      defaults="Can't be manually edited. Automatically calculated from previous tier"
    />
  ),
  pointsValidityInDays: (
    <Trans
      i18nKey="field.pointsValidityInDays"
      defaults="Points are valid for days"
    />
  ),
  position: <Trans i18nKey="field.position" defaults="Position" />,
  preferredLanguage: (
    <Trans i18nKey="field.preferredLanguage" defaults="Preferred language" />
  ),
  profileCreated: (
    <Trans i18nKey="field.profileCreated" defaults="Profile created" />
  ),
  profileLastEdited: (
    <Trans i18nKey="field.profileLastEdited" defaults="Profile last edited" />
  ),
  purchasedFromTo: (
    <Trans i18nKey="field.purchasedFromTo" defaults="Purchased (from-to)" />
  ),
  reason: <Trans i18nKey="field.reason" defaults="Reason" />,
  referenceNumber: (
    <Trans i18nKey="field.referenceNumber" defaults="Reference number" />
  ),
  registrationCode: (
    <Trans i18nKey="field.registrationCode" defaults="Registration code" />
  ),
  restrictedAddons: (
    <Trans i18nKey="field.restrictedAddons" defaults="Restricted addons" />
  ),
  seatPreferences: (
    <Trans i18nKey="field.seatPreferences" defaults="Seat preferences" />
  ),
  showActiveCards: (
    <Trans i18nKey="field.showActiveCards" defaults="Show only active card" />
  ),
  showActiveContacts: (
    <Trans
      i18nKey="field.showActiveContacts"
      defaults="Show only active contacts"
    />
  ),
  showActiveSpecialDiscounts: (
    <Trans
      i18nKey="field.showActiveSpecialDiscounts"
      defaults="Show all special discounts"
    />
  ),
  showActiveTravelPassDiscounts: (
    <Trans
      i18nKey="field.showActiveTravelPassDiscounts"
      defaults="Show all travel pass discounts"
    />
  ),
  showActiveUnits: (
    <Trans i18nKey="field.showActiveUnits" defaults="Show only active units" />
  ),
  showValidTravelPasses: (
    <Trans
      i18nKey="field.showValidTravelPasses"
      defaults="Show only valid travel passes"
    />
  ),
  showValidVouchers: (
    <Trans
      i18nKey="field.showValidVouchers"
      defaults="Show only valid vouchers"
    />
  ),
  specialDiscounts: (
    <Trans i18nKey="field.specialDiscounts" defaults="Special discounts" />
  ),
  startDateFromTo: (
    <Trans i18nKey="field.startDateFromTo" defaults="Start date(from* - to)" />
  ),
  status: <Trans i18nKey="field.status" defaults="Status" />,
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house number" />
  ),
  transactionTimeFrom: (
    <Trans
      i18nKey="field.transactionTimeFrom"
      defaults="Transaction time from"
    />
  ),
  transactionTimeTo: (
    <Trans i18nKey="field.transactionTimeTo" defaults="Transaction time to" />
  ),
  travelPassDiscounts: (
    <Trans
      i18nKey="field.travelPassDiscounts"
      defaults="Travel pass discounts"
    />
  ),
  travelPassNumber: (
    <Trans i18nKey="field.travelPassNumber" defaults="Travel pass number" />
  ),
  uid: <Trans i18nKey="field.uid" defaults="UID" />,
  unit: <Trans i18nKey="field.unit" defaults="Unit" />,
  usedLimit: <Trans i18nKey="field.usedLimit" defaults="Used limit" />,
  validForMultiplier: <Trans i18nKey="field.validFor" defaults="Valid for" />,
  validForType: <Trans i18nKey="field.validForType" defaults="Timespan" />,
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validFromTo: <Trans i18nKey="field.validFromTo" defaults="Valid (from-to)" />,
  validUntil: <Trans i18nKey="field.validUntil" defaults="Valid until" />,
  validityEndFrom: (
    <Trans i18nKey="field.validityEndFrom" defaults="Validity end from" />
  ),
  validityEndTo: (
    <Trans i18nKey="field.validityEndTo" defaults="Validity end to" />
  ),
  vatCode: <Trans i18nKey="field.vatCode" defaults="VAT number" />,
  voucherCode: <Trans i18nKey="field.voucherCode" defaults="Voucher code" />,
  webPage: <Trans i18nKey="field.webPage" defaults="Web page" />,
  zipCode: <Trans i18nKey="field.zipCode" defaults="Zip code" />,
});
