import { createAsyncThunk } from 'store/utils';
import {
  CustomerProfile,
  CustomerProfileCard,
  CustomerProfileCardFilter,
  CustomerProfileConsentHistory,
  CustomerProfileRequest,
  CustomerProfileLoyaltyPoints,
  CustomerProfileLoyaltySummary,
  CustomerProfileSearchFilter,
  CustomerProfileTransactions,
  CustomerProfileTravelPasses,
  CustomerProfileTravelPassFilter,
  CustomerProfileVoucherFilter,
  CustomerProfileVouchers,
  CustomerProfileWithLoyalty,
  TravelPass,
  TravelPassSearchFilter,
  Voucher,
  VoucherSearchFilter,
} from 'dto/customerProfile';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';

export const setCustomerProfileFilter = createAction<
  Partial<CustomerProfileSearchFilter>
>('customerProfile/setCustomerProfileFilter');

export const setTravelPassFilter = createAction<
  Partial<TravelPassSearchFilter>
>('customerProfile/setTravelPassFilter');

export const setVouchersFilter = createAction<Partial<TravelPassSearchFilter>>(
  'customerProfile/setVouchersFilter'
);

export const setCustomerProfileVoucherFilter = createAction<
  Partial<CustomerProfileVoucherFilter>
>('customerProfile/setCustomerProfileVouchersFilter');

export const setCustomerProfileTravelPassFilter = createAction<
  Partial<CustomerProfileTravelPassFilter>
>('customerProfile/setCustomerProfileTravelPassFilter');

export const setCustomerProfileCardFilter = createAction<
  Partial<CustomerProfileCardFilter>
>('customerProfile/setCustomerProfileCardFilter');

export const resetCustomerProfileList = createAction(
  'customerProfile/setCustomerProfileList'
);

export const resetTravelPassSearchTable = createAction(
  'customerProfile/resetTravelPassSearchTable'
);

export const resetVoucherSearchTable = createAction(
  'customerProfile/resetVoucherSearchTable'
);

export const getCustomerProfileList = createAsyncThunk<
  Pagination<CustomerProfile>,
  Partial<CustomerProfileSearchFilter>
>('customerProfile/getCustomerProfileList', async (filter, { dispatch }) => {
  dispatch(setCustomerProfileFilter(filter));
  return (
    await api.get(
      `/customer-profiles${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const getCustomerProfile = createAsyncThunk<
  CustomerProfileWithLoyalty,
  number | undefined
>('customerProfile/getCustomerProfile', async (id) => {
  const profile = (await api.get(`/customer-profiles/${id}`)).data;
  const loyaltySummary = (
    await api.get(`/customer-profiles/${id}/loyalty-programs/points/summary`)
  ).data;

  return { ...profile, loyalty: loyaltySummary };
});

export const getCustomerProfileLoyaltySummary =
  createAsyncThunk<CustomerProfileLoyaltySummary>(
    'customerProfile/getCustomerProfileLoyaltySummary',
    async (_, { getState }) => {
      const { current } = getState().customerProfile;

      return (
        await api.get(
          `/customer-profiles/${current?.id}/loyalty-programs/points/summary`
        )
      ).data;
    }
  );

export const getCustomerProfileConsentHistory = createAsyncThunk<
  Pagination<CustomerProfileConsentHistory>,
  PaginationParams | undefined
>(
  'customerProfile/getCustomerProfileConsentHistory',
  async (filter, { getState }) => {
    const { current } = getState().customerProfile;

    return (
      await api.get(
        `/customer-profiles/${current?.id}/consent-histories${qs.stringify(
          filter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const getCustomerProfileVoucherBalance = createAsyncThunk<number>(
  'customerProfile/getCustomerProfileVoucherBalance',
  async (_, { getState }) => {
    const { current } = getState().customerProfile;

    return (await api.get(`/customer-profiles/${current?.id}/vouchers/summary`))
      .data.balance;
  }
);

export const getCustomerProfileVouchers = createAsyncThunk<
  Pagination<CustomerProfileVouchers>,
  Partial<CustomerProfileVoucherFilter> | undefined
>(
  'customerProfile/getCustomerProfileVouchers',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setCustomerProfileVoucherFilter(values));
    }

    const { current, customerProfileVoucherFilter } =
      getState().customerProfile;

    return (
      await api.get(
        `/customer-profiles/${current?.id}/vouchers${qs.stringify(
          customerProfileVoucherFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const deleteCustomerProfileVouchers = createAsyncThunk<
  void,
  Array<number>
>(
  'customerProfile/deleteCustomerProfileTravelPass',
  async (ids, { getState }) => {
    const { current } = getState().customerProfile;

    await Promise.all(
      ids.map((id) =>
        api.delete(`/customer-profiles/${current?.id}/vouchers/${id}`)
      )
    );
  }
);

export const getVouchers = createAsyncThunk<
  Pagination<Voucher>,
  Partial<VoucherSearchFilter>
>('customerProfile/getVouchers', async (filter, { dispatch }) => {
  dispatch(setVouchersFilter(filter));
  return (
    await api.get(
      `/vouchers${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_BO,
      }
    )
  ).data;
});

export const createCustomerProfileVoucher = createAsyncThunk<
  void,
  Array<number>
>(
  'customerProfile/createCustomerProfileTravelPass',
  async (ids, { getState }) => {
    const { current } = getState().customerProfile;

    await Promise.all(
      ids.map((voucherId) =>
        api.post(`/customer-profiles/${current?.id}/vouchers`, {
          voucherId,
        })
      )
    );
  }
);

export const getTravelPasses = createAsyncThunk<
  Pagination<TravelPass>,
  Partial<TravelPassSearchFilter>
>('customerProfile/getTravelPasses', async (filter, { dispatch }) => {
  dispatch(setTravelPassFilter(filter));
  return (
    await api.get(
      `/travel-passes${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_BO,
      }
    )
  ).data;
});

export const getCustomerProfileTravelPasses = createAsyncThunk<
  Pagination<CustomerProfileTravelPasses>,
  CustomerProfileTravelPassFilter | undefined
>(
  'customerProfile/getCustomerProfileTravelPasses',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setCustomerProfileTravelPassFilter(values));
    }

    const { current, customerProfileTravelPassFilter } =
      getState().customerProfile;

    return (
      await api.get(
        `/customer-profiles/${current?.id}/travel-passes${qs.stringify(
          customerProfileTravelPassFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const createCustomerProfileTravelPass = createAsyncThunk<
  void,
  Array<number>
>(
  'customerProfile/createCustomerProfileTravelPass',
  async (ids, { getState }) => {
    const { current } = getState().customerProfile;

    await Promise.all(
      ids.map((travelPassId) =>
        api.post(`/customer-profiles/${current?.id}/travel-passes`, {
          travelPassId,
        })
      )
    );
  }
);

export const deleteCustomerProfileTravelPass = createAsyncThunk<
  void,
  Array<number>
>(
  'customerProfile/deleteCustomerProfileTravelPass',
  async (ids, { getState }) => {
    const { current } = getState().customerProfile;

    await Promise.all(
      ids.map((id) =>
        api.delete(`/customer-profiles/${current?.id}/travel-passes/${id}`)
      )
    );
  }
);

export const getCustomerProfileTransactions = createAsyncThunk<
  Pagination<CustomerProfileTransactions>,
  PaginationParams | undefined
>(
  'customerProfile/getCustomerProfileTransactions',
  async (filter, { getState }) => {
    const { current } = getState().customerProfile;

    return (
      await api.get(
        `/customer-profiles/${current?.id}/transactions${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data;
  }
);

export const createCustomerProfilePoints = createAsyncThunk<
  CustomerProfileLoyaltyPoints,
  CustomerProfileLoyaltyPoints
>(
  'customerProfile/createCustomerProfilePoints',
  async (payload, { getState }) => {
    const { current } = getState().customerProfile;

    return (
      await api.post(
        `/customer-profiles/${current?.id}/loyalty-programs/points`,
        payload
      )
    ).data;
  }
);

export const updateCustomerProfile = createAsyncThunk<
  CustomerProfile,
  CustomerProfileRequest
>(
  'customerProfile/updateCustomerProfile',
  async ({ id, ...payload }) =>
    (await api.put(`/customer-profiles/${id}`, payload)).data
);

export const getCustomerProfileLoyaltyTargetGroups = createAsyncThunk<
  Array<Classifier>,
  number
>(
  'customerProfile/getCustomerTargetGroups',
  async (id) =>
    (await api.get(`/customer-profiles/${id}/target-groups`)).data.items
);

export const createCustomerProfile = createAsyncThunk<
  CustomerProfile,
  CustomerProfileRequest
>(
  'customerProfile/createCustomerProfile',
  async (payload) => (await api.put(`/customer-profiles/`, payload)).data
);

export const sendPasswordReset = createAsyncThunk<void, number>(
  'customerProfile/sendEmailReset',
  async (id) => (await api.put(`/customer-profiles/${id}/passwords/reset`)).data
);

export const deleteCustomerProfile = createAsyncThunk<void, number>(
  'customerProfile/deleteCustomerProfile',
  async (id) => (await api.delete(`/customer-profiles/${id}`)).data
);

export const getCustomerProfileCards = createAsyncThunk<
  Pagination<CustomerProfileCard>,
  Partial<CustomerProfileCardFilter> | undefined
>(
  'customerProfile/getCustomerProfileCards',
  async (values = {}, { dispatch, getState }) => {
    if (values) {
      dispatch(setCustomerProfileCardFilter(values));
    }

    const { current, customerProfileCardFilter } = getState().customerProfile;

    return (
      await api.get(
        `/customer-profiles/${current?.id}/cards${qs.stringify(
          customerProfileCardFilter,
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`
      )
    ).data;
  }
);

export const createCustomerProfileCard = createAsyncThunk<
  CustomerProfileCard,
  Omit<CustomerProfileCard, 'id'>
>(
  'customerProfile/createCustomerProfileCard',
  async (payload, { getState }) => {
    const { current } = getState().customerProfile;

    return (await api.post(`/customer-profiles/${current?.id}/cards`, payload))
      .data;
  }
);

// TODO Bulk endpoint is needed. Change it once it is available
export const deleteCustomerProfileCard = createAsyncThunk<void, Array<number>>(
  'customerProfile/deleteCustomerProfileCard',
  async (ids, { getState }) => {
    const { current } = getState().customerProfile;

    await Promise.all(
      ids.map((id) =>
        api.delete(`/customer-profiles/${current?.id}/cards/${id}`)
      )
    );
  }
);

export const updateCustomerProfileCard = createAsyncThunk<
  CustomerProfileCard,
  CustomerProfileCard
>(
  'customerProfile/createCustomerProfileCard',
  async (payload, { getState }) => {
    const { current } = getState().customerProfile;

    return (
      await api.put(
        `/customer-profiles/${current?.id}/cards/${payload.id}`,
        payload
      )
    ).data;
  }
);

export const getCustomerProfileListLight = createAsyncThunk<
  Array<CustomerProfile>,
  Partial<CustomerProfileSearchFilter>
>(
  'customerProfile/getCustomerProfileListLight',
  async (filter, { dispatch }) => {
    dispatch(setCustomerProfileFilter(filter));
    return (
      await api.get(
        `customer-profiles-light${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data.items;
  }
);
