import { Option } from '@fleet/shared/dto/option';

export const transactionTypeOptions: Array<Option<string>> = [
  {
    value: 'TRANSACTION_TYPE.BUS_TICKET',
    label: 'Ticket',
  },
  {
    value: 'TRANSACTION_TYPE.TRAVEL_PASS',
    label: 'Travel pass',
  },
];

export const conversionRuleOptions: Array<Option<string>> = [
  {
    value: 'LOYALTY_PROGRAM_POINTS_CONVERSION_RULE.TRANSACTION_COST',
    label: 'Transaction',
  },
  {
    value: 'LOYALTY_PROGRAM_POINTS_CONVERSION_RULE.DISTANCE',
    label: 'Distance',
  },
];

export const assigningRuleOptions: Array<Option<string>> = [
  {
    value: 'LOYALTY_PROGRAM_POINTS_ASSIGNING_RULE.PASSENGER',
    label: 'Passenger',
  },
  {
    value: 'LOYALTY_PROGRAM_POINTS_ASSIGNING_RULE.PAYER',
    label: 'Payer',
  },
  {
    value:
      'LOYALTY_PROGRAM_POINTS_ASSIGNING_RULE.PASSENGER_FIRST_FALLBACK_PAYER',
    label: 'Passenger with a fallback to payer',
  },
  {
    value:
      'LOYALTY_PROGRAM_POINTS_ASSIGNING_RULE.PAYER_FIRST_FALLBACK_PASSENGER',
    label: 'Payer with a fallback to passenger',
  },
];

export const languageOptions: Array<Option<string>> = [
  {
    value: 'CULTURE.EN_GB',
    label: 'English',
  },
  {
    value: 'CULTURE.ET_EE',
    label: 'Estonian',
  },
  {
    value: 'CULTURE.LT_LT',
    label: 'Lithuanian',
  },
];

export const listOfTimeMetrics = [
  {
    value: 'days',
    label: 'days',
  },
  {
    value: 'months',
    label: 'months',
  },
];

export const listOfReasons = [
  {
    value: 'LOYALTY_PROGRAM_POINT_SOURCE.JOINING_LOYALTY_PROGRAM',
    label: 'Joining loyalty program',
  },
  {
    value: 'LOYALTY_PROGRAM_POINT_SOURCE.BIRTHDAY_PRESENT',
    label: 'Birthday present',
  },
  {
    value: 'LOYALTY_PROGRAM_POINT_SOURCE.COMPLAINT',
    label: 'Complaint',
  },
  {
    value: 'LOYALTY_PROGRAM_POINT_SOURCE.SPECIAL_OFFER',
    label: 'Special offer',
  },
  {
    value: 'LOYALTY_PROGRAM_POINT_SOURCE.OTHER_REASON',
    label: 'Other reason',
  },
];
