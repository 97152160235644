import React, { FC, useCallback, useState } from 'react';
import { Button, Icon, Loadable, Modal, useForm } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { Row } from 'react-table';
import { Voucher } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import {
  createCustomerProfileVoucher,
  getCustomerProfileVouchers,
} from 'features/customerProfile/customerProfileActions';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { currentCustomerProfileSelector } from 'features/customerProfile/customerProfileSelectors';
import { vouchersSearchTableLoadingSelector } from 'features/loading/loadingSelectors';
import { ConnectVouchersTable } from 'routes/customerProfile/ConnectVouchersTable';
import { ConnectVouchersSearchForm } from 'routes/customerProfile/ConnectVouchersSearchForm';

interface ConnectVouchersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ConnectVouchersModal: FC<ConnectVouchersModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [selectedRows, setSelectedRows] = useState<Row<Voucher>[]>([]);
  const alert = useAlert();
  const dispatch = useDispatch();
  const loading = useSelector(vouchersSearchTableLoadingSelector);
  const customerProfile = useSelector(currentCustomerProfileSelector);

  const onSubmit = useCallback(async () => {
    const voucherIds = selectedRows.map((row) => row.original.id);

    await dispatch(createCustomerProfileVoucher(voucherIds))
      .unwrap()
      .then(() => {
        alert.success(
          <TransAlert
            i18nKey="voucherConnected"
            values={{
              firstName: customerProfile?.firstName,
              lastName: customerProfile?.lastName,
            }}
          />
        );
        dispatch(getCustomerProfileVouchers());
        onClose();
      })
      .catch(() => {
        onClose();
        dispatch(getCustomerProfileVouchers());
      });
  }, [
    alert,
    dispatch,
    selectedRows,
    customerProfile?.firstName,
    customerProfile?.lastName,
    onClose,
  ]);

  const { handleSubmit, submitting } = useForm({
    onSubmit,
    subscription: { submitting: true },
  });

  const onSelectedRowsChange = (rows: Row<Voucher>[]) => {
    setSelectedRows(rows);
  };

  return (
    <Loadable loading={loading}>
      <Modal
        onClose={onClose}
        title={<TransTitle i18nKey="connectVouchers" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              startIcon={<Icon name="check" size={16} />}
              variant="contained"
              type="submit"
              disabled={!selectedRows.length || submitting}
            >
              <TransButton i18nKey="confirm" />
            </Button>
          </form>
        }
        open={isOpen}
      >
        <ConnectVouchersSearchForm />
        <ConnectVouchersTable onSelectedRowsChange={onSelectedRowsChange} />
      </Modal>
    </Loadable>
  );
};
