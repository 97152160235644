import type { FC } from 'react';
import { CustomerProfileList } from 'routes/customerProfile/CustomerProfileList';
import { CustomerProfileEdit } from 'routes/customerProfile/CustomerProfileEdit';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

export interface CustomerProfileTypesProps
  extends RouteComponentProps<{ typeId: string }> {}

export const CustomerProfile: FC<CustomerProfileTypesProps> = ({
  match: { path },
}) => (
  <Switch>
    <Route path={path} component={CustomerProfileList} exact />
    <Route path={`${path}/:id`} component={CustomerProfileEdit} exact />
  </Switch>
);
